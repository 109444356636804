import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { get } from "../../../api/Request";
import TextContent from "../../TextContent";
import { useNavigate } from "react-router-dom";
import { config } from "../../../data/Constants";
const SIF: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  return (
    <>
      <TextContent id={536} />

      <Button
        hidden={user?.clinic.integration_enabled !== "sif"}
        onClick={() => {
          window.location.replace(config.SIF_URL);
        }}
      >
        {t("integrations.authenticateSif")}
      </Button>

      <Button
        hidden={user?.clinic.integration_enabled === "sif"}
        type="primary"
        onClick={() => {
          get("integrations/enableSifIntegration").then(r => {
            setUser(r.data.user);
            window.location.replace(config.SIF_URL);
          });
        }}
      >
        {t("integrations.enableIntegration")}
      </Button>
      {user?.clinic.integration_enabled === "sif" && (
        <Button
          color="red"
          variant="filled"
          onClick={() => {
            get("integrations/disableSifIntegration").then(r => {
              setUser(r.data.user);

              // navigate("/settings/integrations/sif-auth");
            });
          }}
        >
          {t("integrations.disable")}
        </Button>
      )}
    </>
  );
};

export default SIF;
