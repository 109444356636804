import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Input,
  Skeleton,
  Result,
  Divider,
  Button,
  Form,
  Dropdown,
  MenuProps,
  Space
} from "antd";
import { useSearchParams } from "react-router-dom";
import MyPageHeader from "../../components/layout/MyPageHeader";
import debounce from "lodash/debounce";

import { useTranslation } from "react-i18next";
import {
  ExerciseInPlan,
  ExercisesGetParam,
  ExercisesList,
  PlanMode
} from "../../types/PlanUpsert";
import { ImageType } from "../../types/Plan";
import UPExercisesPreviewMemo from "./UPExercisesPreview";
import { get } from "../../api/Request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UPExerciseMemo from "./UPExercise";
import {
  faChild,
  faEllipsis,
  faPlus,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import { useGlobalContext } from "../../context/GlobalContext";
import { useAuth } from "../../context/AuthContext";
import UPCatsMemo from "./UPCats";
import { useLocationState } from "react-router-use-location-state";
import InfiniteScroll from "react-infinite-scroll-component";
import UpsertPatientModal from "../patients/UpsertPatientModal";

const { Search } = Input;

interface Props {}

export const defaultParam: ExercisesGetParam = {
  category_id: 0,
  muscle_id: 0,
  diagnosis_id: 0,
  page: 1,
  my_exercises: false,
  my_fav_exercises: false,
  search: ""
};

const UpsertPlan: React.FC<Props> = () => {
  // console.log("rendering MAIN COMPONENT");
  const exerciseListRef = useRef<HTMLSpanElement | null>(null);

  const { t } = useTranslation();
  const { isTabletOrMobile } = useGlobalContext();
  const { user } = useAuth();
  const [pageTitle, setPageTitle] = useState("");
  const [showTemplates, setShowTemplates] = useState(false);
  const [mobilePageTitle, setMobilePageTitle] = useState("");
  const [params] = useSearchParams();
  const mode: PlanMode = (params.get("mode") as PlanMode) ?? "plan";
  const id = parseInt(params.get("id") ?? "0");

  const [patient, setPatient] = useState<number | null>(null);
  const [searchForm] = Form.useForm();

  const getLang = () => {
    const lang =
      (params.get("lang") as string) ?? (user?.practitioner.lang as string);
    return lang;
  };

  const [lang, setLang] = useState(getLang());

  const [exercisesGetParam, setExerciseGetParam] =
    useState<ExercisesGetParam>(defaultParam);

  const updateParams = useCallback((params: ExercisesGetParam) => {
    setExerciseGetParam({ ...params });
  }, []);

  const [title, setTitle] = useState("");
  const [exercisesInPlan, setExercisesInPlan] = useState<ExerciseInPlan[]>([]);
  const [newExercise, setNewExercise] = useState<ExerciseInPlan | null>(null);
  const [removeExercise, setRemoveExercise] = useState<ExerciseInPlan | null>(
    null
  );
  const [showPatientModal, setShowPatientModal] = useState(false);

  const [imageType, setImageType] = useState<ImageType>(ImageType.photo);

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useLocationState("tab", id > 0 ? "preview" : "library");

  const [exercises, setExercises] = useState<ExercisesList>({
    exercises: [],
    total_items: 0,
    items_per_page: 20,
    total_pages: 0,
    current_page: 0,
    lang: lang as string,
    has_more: true
  });

  useEffect(() => {
    if (user) {
      // if new plan change lang to user lang
      if (id === 0) {
        if (user.practitioner.lang !== lang) {
          setLang(user.practitioner.lang);
          updateParams({ ...defaultParam });
          setTitle("");
        }
      } else {
        // existing plan, keep same language
        // generateNotes(user.id);
      }
    }
  }, [user, id, lang]);

  const updateTitle = useCallback(title => {
    setTitle(title);
    setMobilePageTitle(title);
  }, []);

  const addNewExercise = useCallback(e => {
    // console.log(e, "exercise to add");
    let exerciseToAdd;
    if (e.advice_sheet_pdf)
      exerciseToAdd = {
        id: e.id,
        advice_sheet_pdf: e.advice_sheet_pdf,
        title: e.title,
        desc: e.desc,
        image: e.image,
        image_thumbnail: e.image_thumbnail,
        lineart: e.lineart,
        lineart_thumbnail: e.lineart_thumbnail
      };
    else
      exerciseToAdd = {
        id: e.id,
        advice_sheet_pdf: "",
        title: e.title,
        desc: e.desc,
        image: e.image,
        image_thumbnail: e.image_thumbnail,
        lineart: e.lineart,
        lineart_thumbnail: e.lineart_thumbnail,
        time: "",
        repetitions: "",
        sets: "",
        rest: "",
        days_per_week: "",
        bothsides: false
      };
    setNewExercise(exerciseToAdd);
    setExercisesInPlan(exercises => [...exercises, exerciseToAdd]);
  }, []);

  const removeExerciseFromPlan = (e: ExerciseInPlan) => {
    setRemoveExercise(e);
  };

  const scrollToExerciseList = () => {
    if (exerciseListRef.current) {
      const rect = exerciseListRef.current.getBoundingClientRect();
      const isInViewport =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      if (!isInViewport) {
        exerciseListRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  const getExercises = useCallback(() => {
    if (exercisesGetParam !== defaultParam) {
      setLoading(true);
      if (exercisesGetParam.page === 1) {
        setTab("exercises");
        scrollToExerciseList();

        //clear previous exercises if new search/cat
        setExercises({ ...exercises, exercises: [] });
      }
      // console.log("getting exercises...");

      get<ExercisesList>("exercise/load/", { ...exercisesGetParam, lang: lang })
        .then(r => {
          if (exercisesGetParam.page === 1) {
            setExercises(r.data);
            // if (exercisesGetParam.search === "") scrollToExerciseList();
          } else {
            setExercises({
              ...r.data,
              exercises: exercises.exercises.concat(r.data.exercises)
            });
          }
        })
        .catch(_ => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [exercisesGetParam]);

  useEffect(() => {
    if (!loading) getExercises();
    if (
      exercisesGetParam.category_id ||
      exercisesGetParam.diagnosis_id ||
      exercisesGetParam.muscle_id ||
      exercisesGetParam.my_fav_exercises ||
      exercisesGetParam.my_exercises
    )
      searchForm.setFieldsValue({ search: "" });
  }, [exercisesGetParam]);

  useEffect(() => {
    const patientParam = params.get("patient");
    if (patientParam === null || patientParam === "" || patientParam === "0")
      setPatient(null);
    else setPatient(parseInt(patientParam));
  }, [params]);
  useEffect(() => {
    if (mode === "template") {
      setPageTitle(id > 0 ? t("template.edit") : t("template.createNew"));
      setMobilePageTitle(id > 0 ? t("template.edit") : t("template.createNew"));
    } else {
      setPageTitle(id > 0 ? t("plan.editPlan") : t("plan.createNewPlan"));
      setMobilePageTitle(id > 0 ? t("plan.editPlan") : t("plan.createNewPlan"));
    }
  }, [id, mode]);

  // let { tab } = useParams();

  const areExercisesGetParamsSet = useCallback(() => {
    if (
      exercisesGetParam.category_id ||
      exercisesGetParam.diagnosis_id ||
      exercisesGetParam.muscle_id ||
      exercisesGetParam.my_fav_exercises ||
      exercisesGetParam.my_exercises ||
      exercisesGetParam.search.length > 0
    )
      return true;

    return false;
  }, [exercisesGetParam]);

  const mobileMenuItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button
          key="lft1"
          type="text"
          onClick={() => {
            setShowTemplates(true);
          }}
        >
          {t("template.loadFromTemplate")}
        </Button>
      )
    },
    {
      key: "2",
      label: (
        <Button
          key="anp2"
          type="text"
          onClick={() => {
            // setPatientUpsertData(newPatientUpsert);
            setShowPatientModal(true);
          }}
        >
          {t("patient.addNewPatient")}
        </Button>
      )
    }
  ];

  return (
    <>
      <MyPageHeader
        title={
          isTabletOrMobile
            ? tab === "exercises"
              ? mobilePageTitle
              : tab === "library"
              ? t("planUpsert.library")
              : t("planUpsert.preview")
            : pageTitle
        }
        customBackText={t("cancel")}
        extra={[
          !isTabletOrMobile && (
            <span hidden={mode !== "plan"} key="btns">
              <Space.Compact key="bg">
                <Button
                  key="anp1"
                  color="green"
                  variant="solid"
                  onClick={() => {
                    setShowTemplates(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />

                  {t("template.loadFromTemplate")}
                </Button>
                <Button
                  key="anp2"
                  color="primary"
                  variant="solid"
                  onClick={() => {
                    // setPatientUpsertData(newPatientUpsert);
                    setShowPatientModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />

                  {t("patient.addNewPatient")}
                </Button>
              </Space.Compact>
            </span>
          )
        ]}
        mobileRight={
          <Dropdown
            overlayStyle={{ paddingTop: 10 }}
            menu={{ items: mobileMenuItems }}
            trigger={["click"]}
          >
            <FontAwesomeIcon icon={faEllipsis} style={{ padding: "5px 8px" }} />
          </Dropdown>
        }
      />
      <UpsertPatientModal
        initialData={{
          id: 0,
          first_name: "",
          last_name: "",
          send_login_details: true,
          patient_consent: true,
          dial_code: user?.settings.default_phone_dial_code,
          image_type: ImageType.photo
        }}
        showModal={showPatientModal}
        onDone={id => {
          setShowPatientModal(false);
          setPatient(id);
          // getTabelData();
          // openPatient(id);
        }}
        onCancel={() => {
          setShowPatientModal(false);
        }}
      />

      <div className="box" id="upsert-plan">
        <Row gutter={{ xs: 0, sm: 16, md: 16, lg: 16 }}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={6}
            xl={6}
            hidden={isTabletOrMobile && tab !== "library"}
          >
            <UPCatsMemo
              onSelect={e => {
                updateParams(e);
              }}
              // onSelect={_ => {}}
              onTitleChange={updateTitle}
              // onTitleChange={_ => {}}
              lang={lang}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            hidden={isTabletOrMobile && tab !== "exercises"}
          >
            <Row
              gutter={{ xs: 0, sm: 16, md: 16, lg: 16 }}
              style={{ overflow: "hidden" }}
            >
              <Col xs={24}>
                {!isTabletOrMobile && <h2>{title}</h2>}
                <span ref={exerciseListRef}></span>
                <Form form={searchForm}>
                  <Form.Item name="search">
                    <Search
                      // className="margin-bottom"
                      allowClear
                      placeholder={t("search")}
                      // value={exercisesGetParam.search}
                      onChange={debounce(e => {
                        updateTitle(t("search") + ": " + e.target.value);
                        updateParams({
                          ...defaultParam,
                          search: e.target.value
                        });
                      }, 300)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Form>
              </Col>

              <>
                {exercises.exercises.length === 0 &&
                  !loading &&
                  !areExercisesGetParamsSet() && (
                    <Result
                      style={{ margin: "0 auto" }}
                      icon={<FontAwesomeIcon icon={faChild} size="4x" />}
                      title={t("planUpsert.pleaseSelectCategory")}
                    />
                  )}
                {exercises.exercises.length === 0 &&
                  !loading &&
                  (exercisesGetParam.search.length > 0 ||
                    exercisesGetParam.my_fav_exercises ||
                    exercisesGetParam.my_exercises) && (
                    <div className="center">
                      <Result
                        icon={<FontAwesomeIcon icon={faSearch} size="4x" />}
                        title={t("planUpsert.noExercisesFound")}
                      />
                    </div>
                  )}
              </>
            </Row>
            <div>
              <InfiniteScroll
                dataLength={exercises.exercises.length}
                next={() => {
                  // console.log("next");
                  updateParams({
                    ...exercisesGetParam,
                    page: exercisesGetParam.page + 1
                  });
                }}
                hasMore={exercises.has_more}
                loader={
                  loading &&
                  false && (
                    <>
                      <h4 style={{ textAlign: "center", margin: "0 auto" }}>
                        {t("loading")}...
                      </h4>
                      <Skeleton paragraph={{ rows: 2 }} active />
                    </>
                  )
                }
                endMessage={
                  exercises.exercises.length > 0 && (
                    <Divider plain>{t("planUpsert.endOfResults")}</Divider>
                  )
                }
                // scrollableTarget="scrollableDiv"
              >
                <Row
                  className="up-exercises-row"
                  gutter={{ xs: 8, sm: 16, md: 16, lg: 24 }}
                >
                  {exercises.exercises.map((e, index) => (
                    <UPExerciseMemo
                      key={"exerciseView" + index}
                      added={
                        exercisesInPlan.find(eip => eip.id === e.id)
                          ? true
                          : false
                      }
                      // added={false}
                      exercise={e}
                      imageType={imageType}
                      onAdd={e => {
                        addNewExercise(e);
                      }}
                      onRemove={e => {
                        removeExerciseFromPlan(e);
                      }}
                    />
                  ))}
                </Row>
              </InfiniteScroll>
            </div>

            {loading && false && (
              <>
                <Skeleton.Input
                  active
                  size="small"
                  block
                  style={{ marginBottom: 16 }}
                />
                <br />
                <h3 style={{ textAlign: "center", margin: "32 auto" }}>
                  {t("loading")}...
                </h3>
                <br />
                <Skeleton.Input
                  active
                  size="small"
                  block
                  style={{ marginTop: 16 }}
                />
              </>
            )}
            {loading && <Skeleton active />}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={6}
            xl={6}
            hidden={isTabletOrMobile && tab !== "preview"}
          >
            <UPExercisesPreviewMemo
              imageType={imageType}
              id={id}
              patient={patient}
              mode={mode}
              newExercise={newExercise}
              removeExercise={removeExercise}
              showTemplates={showTemplates}
              lang={lang}
              setShowTemplates={setShowTemplates}
              onUpdateExercises={setExercisesInPlan}
              onChangeImageType={setImageType}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UpsertPlan;
