import React, { useEffect, useState } from "react";
import { Button, Space } from "antd";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import Column from "../../components/layout/Col";
import { Link } from "react-router-dom";
import MyPageHeader from "../../components/layout/MyPageHeader";
import ManageTemplateCategories from "./ManageTemplateCategoriess";
import TemplatesTable from "./TemplatesTable";
import { useLocation } from "react-router-dom";
import { get } from "../../api/Request";
import { RmpTemplateCat, UserTemplateCat } from "../../types/Plan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import { UserRole } from "../../types/Practitioner";

const Templates: React.FC = () => {
  // console.log("load component templates");
  const { search } = useLocation();

  const [showCatsModal, setShowCatsModal] = useState(false);
  const [userCats, setUserCat] = useState<UserTemplateCat[]>();
  const [rmpCats, setRmpCats] = useState<RmpTemplateCat[]>();
  const getCats = () => {
    get<UserTemplateCat[]>("template/userTemplateCats")
      .then(r => {
        setUserCat(r.data);
        // console.log(r.data, "user cats");
      })
      .catch(_ => {})
      .finally(() => {});
  };
  const getRmpCats = () => {
    get<RmpTemplateCat[]>("template/templateCats")
      .then(r => {
        setRmpCats(r.data);
        // console.log(r.data, "rmp cats");
      })
      .catch(_ => {})
      .finally(() => {});
  };

  useEffect(() => {
    // if (userCats === []) {
    // console.log("loading template cats");
    getCats();
    getRmpCats();
    // }
  }, []);

  const { user } = useAuth();
  const { t } = useTranslation();
  let query = new URLSearchParams(search);

  return (
    <>
      <Column size="standard" style={{ marginTop: -50 }} key="col">
        <MyPageHeader
          key="ph"
          // onBack={() => null}

          title={t("menu.templates")}
          // subTitle="This is a subtitle"
          extra={[
            <Space.Compact key="btns">
              <Button key="cnp" color="green" variant="solid">
                <Link to="/exercise-plan?id=0&mode=template">
                  <FontAwesomeIcon icon={faPlus} />

                  {t("template.createNew")}
                </Link>
              </Button>
              <Button
                key="anp"
                color="primary"
                variant="solid"
                onClick={() => {
                  setShowCatsModal(true);
                }}
              >
                <FontAwesomeIcon icon={faList} />

                {t("template.manageCats")}
              </Button>
            </Space.Compact>
          ]}
        />
        {user && rmpCats && userCats && (
          <TemplatesTable
            initialData={{
              page: query.has("page") ? parseInt(query.get("page") ?? "1") : 1,
              pageSize: parseInt(query.get("pageSize") ?? "10") ?? 10,
              sort: query.get("sort") ?? "created",
              sortDirection: query.get("sortDirection") ?? "descend",
              templateType: query.get("templateType") ?? "user",
              search: query.get("search") ?? "",
              catId: parseInt(query.get("catId") ?? "0") ?? 0,
              practitionerId:
                user.practitioner.can_view_other_patients ||
                user.practitioner.role === UserRole.owner
                  ? 0
                  : user.practitioner.id
            }}
            userCats={userCats}
            rmpCats={rmpCats}
            isInModal={false}
            onSelectTemplate={() => {}}
          />
        )}
      </Column>
      {userCats && (
        <ManageTemplateCategories
          showModal={showCatsModal}
          cats={userCats}
          onDone={() => {
            getCats();
            setShowCatsModal(false);
          }}
          onCancel={() => {
            setShowCatsModal(false);
          }}
        />
      )}
    </>
  );
};

function areEqual(prevProps, nextProps) {
  // console.log("prev, next preview", prevProps, nextProps);

  return true;
  // return (
  //   prevProps.key === nextProps.key &&
  //   prevProps.imageType === nextProps.imageType &&
  //   prevProps.added === nextProps.added
  // );
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

const TemplatesMemo = React.memo(Templates, areEqual);
export default TemplatesMemo;
// export default React.memo(Templates, areEqual);
// export default Templates;
