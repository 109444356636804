import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "antd-mobile";
import Button from "antd/es/button";
import { BaseButtonProps } from "antd/es/button/button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../context/GlobalContext";
import MyModal from "../MyModal";

interface Props {
  btnText: string | JSX.Element;
  noBtn?: boolean;
  modalBtnText?: string;
  message: string;
  btnProps?: BaseButtonProps;
  onConfirm: () => void;
  onClick?: (e: any) => void;
}
const ConfirmButton: React.FC<Props> = ({ onConfirm, ...props }) => {
  const { t } = useTranslation();
  const { isTabletOrMobile } = useGlobalContext();
  const [showModal, setShowModal] = useState(false);

  const openDialog = () => {
    if (isTabletOrMobile)
      Dialog.confirm({
        content: props.message,
        confirmText: props.modalBtnText ?? props.btnText,
        cancelText: t("cancel"),
        onConfirm: () => {
          onConfirm();
        }
      });
    else setShowModal(true);
  };
  return (
    <>
      {props.noBtn ? (
        <div
          onClick={e => {
            e.stopPropagation();
            openDialog();
          }}
        >
          {props.btnText}
        </div>
      ) : (
        <Button
          {...props.btnProps}
          onClick={e => {
            e.stopPropagation();
            openDialog();
          }}
        >
          {props.btnText}
        </Button>
      )}

      <MyModal
        title={t("confirm")}
        style={{ maxWidth: 350 }}
        open={showModal}
        // centered
        onOk={e => {
          setShowModal(false);
          onConfirm();
          e.stopPropagation();
        }}
        onCancel={e => {
          setShowModal(false);
          e.stopPropagation();
        }}
        okText={props.modalBtnText ?? props.btnText}
        cancelText={t("cancel")}
      >
        <FontAwesomeIcon
          style={{ color: "hotpink" }}
          icon={faExclamationCircle}
        />{" "}
        {props.message}
      </MyModal>
    </>
  );
};

export default ConfirmButton;
