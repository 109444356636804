import { Button, Result, Skeleton, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import { formatDate, formatTime } from "../../helpers/dateHelper";
import { ClinicalNote } from "../../types/Patient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faNotesMedical,
  faPlus,
  faPrint
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pract from "../../components/helper/Pract";
import {
  faEdit,
  faEnvelope,
  faTrashAlt
} from "@fortawesome/free-regular-svg-icons";
import ConfirmButton from "../../components/helper/ConfirmButton";
import { get, post } from "../../api/Request";
import { Dialog, List, SwipeAction } from "antd-mobile";
import { config } from "../../data/Constants";
import { useAuth } from "../../context/AuthContext";
import NoteUpsertModal from "./NoteUpsertModal";
import CustomNotesEmailModal from "./CustomNotesEmailModal";
import { uploadBodyCharts } from "./BodyChartUtils";

interface Props {
  patientId: number;
  firstName: string;
  lastName: string;
  newNote?: number;

  onChange: () => void;
}

const PatientNotes: React.FC<Props> = ({
  patientId,
  firstName,
  lastName,
  newNote,
  onChange
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [notes, setNotes] = useState<ClinicalNote[]>();
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [noteEamilId, setNoteEmailId] = useState(0);
  const [showCustomEmailModal, setShowCustomEmailModal] = useState(false);

  const [noteId, setNoteId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  const { Text } = Typography;

  const { language, isTabletOrMobile } = useGlobalContext();

  useEffect(() => {
    if (newNote !== undefined) {
      setNoteId(0);
      setShowUpsertModal(true);
    }
    // console.log(newNote, "newNote");
  }, [newNote]);

  const editNote = (id: number) => {
    setNoteId(id);
    setShowUpsertModal(true);
  };

  useEffect(() => {
    if (patientId > 0) getNotes();
  }, [patientId]);

  const getNotes = () => {
    setLoading(true);
    get<ClinicalNote[]>("note/all/" + patientId)
      .then(r => {
        setNotes(r.data);
      })
      .catch(_ => {
        // navigate("/patients", { replace: true });
      })
      .finally(() => setLoading(false));
  };
  const columns: ColumnsType<ClinicalNote> = [
    {
      title: t("created"),
      dataIndex: "created",
      key: "created",
      className: "pointer",
      sorter: (a, b) => a.created.localeCompare(b.created),
      defaultSortOrder: "descend", // Set default order to descending

      render: date => (
        <span className="tsec_col" title={formatTime(date)}>
          {formatDate(date, language)}
        </span>
      ),
      responsive: ["md"]
    },
    {
      title: t("updated"),
      dataIndex: "updated",
      key: "updated",
      className: "pointer",
      sorter: (a, b) => a.updated.localeCompare(b.updated),
      render: date => (
        <span className="tsec_col" title={formatTime(date)}>
          {formatDate(date, language)}
        </span>
      ),
      responsive: ["md"]
    },

    {
      title: t("practitioner"),
      key: "practitioner",
      className: "pointer",
      sorter: (a, b) => a.created.localeCompare(b.created),
      render: (_, record) => <Pract id={record.practitioner_id} />,
      responsive: ["md"]
    },
    {
      key: "actions",
      className: "pointer",
      render: (text, record) => (
        <Space.Compact
          style={{ float: "right" }}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Button
            key="etr"
            title={t("edit")}
            variant="filled"
            color="primary"
            onClick={e => {
              // navigate(getPlanEditLink(record));
              setNoteId(record.id);
              setShowUpsertModal(true);
              e.stopPropagation();
            }}
            size="middle"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            key="eplan"
            size="middle"
            variant="filled"
            color="primary"
            title={t("sendEmail")}
            loading={emailLoading}
            onClick={e => {
              e.stopPropagation();
              handleEmailClick(record);
            }}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </Button>

          <Button
            key="pplan"
            size="middle"
            title={t("print")}
            variant="filled"
            color="primary"
            loading={printLoading}
            onClick={e => {
              // e.stopPropagation();
              setPrintLoading(true);
              let data = {
                id: record.id.toString(),
                action: "print"
              };
              uploadBodyCharts(data.id, record.body_charts)
                .then(() => {
                  window.open(
                    config.API_URL +
                      "note/printDownload?" +
                      new URLSearchParams(data).toString(),
                    "_blank"
                  );
                })
                .finally(() => {
                  setPrintLoading(false);
                });
            }}
          >
            <FontAwesomeIcon icon={faPrint} />
          </Button>

          <ConfirmButton
            key="4"
            modalBtnText={t("delete")}
            btnText={<FontAwesomeIcon icon={faTrashAlt} />}
            message={t("confirmations.delete")}
            btnProps={{ variant: "filled", color: "red", size: "middle" }}
            onConfirm={() => {
              post("note/delete/", { id: record.id })
                .then(() => {
                  getNotes();
                })
                .catch(e => {});
            }}
          />
        </Space.Compact>
      )
    }
  ];

  const handleEmailClick = note => {
    setEmailLoading(true);
    uploadBodyCharts(note.id, note.body_charts)
      .then(() => {
        setNoteEmailId(note.id);
        setShowCustomEmailModal(true);
      })
      .catch(e => {
        console.error("Error uploading body charts:", e);
      })
      .finally(() => {
        setEmailLoading(false);
      });
  };

  if (notes === undefined) return <Skeleton active />;
  else
    return (
      <>
        {!isTabletOrMobile && notes.length > 0 && (
          <>
            <Table
              pagination={{
                position: ["bottomCenter"],
                hideOnSinglePage: true
              }}
              // dataSource={filteredNotes}
              dataSource={notes}
              columns={columns}
              loading={loading}
              rowKey="id"
              onRow={(record, other) => {
                // prevent click on last row
                // console.log(record, other, "record");
                return {
                  onClick: event => {
                    editNote(record.id);
                  },
                  onDoubleClick: event => {}, // double click row
                  onContextMenu: event => {}, // right button click row
                  onMouseEnter: event => {}, // mouse enter row
                  onMouseLeave: event => {} // mouse leave row
                };
              }}
            />
          </>
        )}
        {isTabletOrMobile && notes.length > 0 && (
          <List>
            {notes.length > 0 &&
              notes.map(n => (
                <SwipeAction
                  // ref={ref}
                  key={n.id}
                  closeOnAction={true}
                  closeOnTouchOutside={false}
                  rightActions={[
                    {
                      key: "delete",
                      text: t("delete"),
                      color: "danger",
                      onClick: e => {
                        Dialog.confirm({
                          content: t("confirmations.deletePlan"),
                          confirmText: t("delete"),
                          cancelText: t("cancel"),
                          onConfirm: () => {
                            post("note/delete/", { id: n.id })
                              .then(() => {
                                onChange();
                              })
                              .catch(e => {});
                          }
                        });
                      }
                    },
                    {
                      key: "edit",
                      text: t("edit"),
                      onClick: e => {
                        // navigate(getPlanEditLink(n));
                        editNote(n.id);
                        e.stopPropagation();
                      }
                    }
                  ]}
                >
                  <List.Item
                    className="one-line-desc"
                    key={n.id}
                    clickable
                    onClick={() => {
                      editNote(n.id);
                    }}
                    prefix={
                      <FontAwesomeIcon
                        icon={faNotesMedical}
                        className="prefix-icon"
                        style={{
                          color: "#1179bb"
                        }}
                      />
                    }
                    arrow={
                      <>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </>
                    }
                    description={
                      <>
                        <Text type="secondary">
                          <Pract id={n.practitioner_id} />
                        </Text>
                      </>
                    }
                  >
                    {formatDate(n.created, language)}
                  </List.Item>
                </SwipeAction>
              ))}
          </List>
        )}
        {notes.length === 0 && patientId ? (
          <Result
            icon={<FontAwesomeIcon icon={faNotesMedical} size="4x" />}
            title={t("cn.noNotes")}
            subTitle={t("cn.nncn")}
            extra={
              <Button
                variant="solid"
                color="green"
                onClick={() => {
                  setNoteId(0);
                  setShowUpsertModal(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> {t("cn.add")}
              </Button>
            }
          />
        ) : (
          <></>
        )}
        <NoteUpsertModal
          id={noteId}
          patientId={patientId}
          patientName={firstName + " " + lastName}
          onDone={() => {
            // setShowUpsertModal(false);
            getNotes();
          }}
          onCancel={() => {
            setShowUpsertModal(false);
            // getNotes();
          }}
          showModal={showUpsertModal}
        />

        <CustomNotesEmailModal
          id={noteEamilId}
          showModal={showCustomEmailModal}
          onCancel={() => {
            setShowCustomEmailModal(false);
          }}
          onDone={() => {
            setShowCustomEmailModal(false);
          }}
        />
      </>
    );
};

export default PatientNotes;
