import React, { useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/lib/table";

import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { useGlobalContext } from "../../context/GlobalContext";
import { formatDate } from "../../helpers/dateHelper";
import { Button, Descriptions, Input, Space, Typography, Select } from "antd";
import {
  PractitionerList,
  PractitionerUpsert,
  UserRole
} from "../../types/Practitioner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import ConfirmButton from "../../components/helper/ConfirmButton";
import UpsertPractitionerModal from "./UpsertPractitionerModal";
import PractitionerStatsModal from "./PractitionerStatsModal";

const { Option } = Select;

interface Props {
  showUpsertModal: boolean;
  setShowUpsertModal: (opened: boolean) => void;
}
const Practitioners: React.FC<Props> = ({
  showUpsertModal,
  setShowUpsertModal
}) => {
  const { Search } = Input;
  const navigate = useNavigate();
  const { user } = useAuth();
  const { Text } = Typography;

  const { t } = useTranslation();
  const { language } = useGlobalContext();
  const newPractitionerData: PractitionerUpsert = {
    id: 0,
    role: UserRole.practitioner,
    archived: false,
    can_view_other_patients: true,
    access_to_all_clinics: true,
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    clinics: [user?.clinic.id ?? 0],
    last_login: null,
    require_password_change: true,
    share_templates: true,
    sldve: true
  };
  const [practitioner, setPractitioner] =
    useState<PractitionerUpsert>(newPractitionerData);
  const [practitioners, setPractitioners] = useState<PractitionerList[]>([]);

  const [search, setSearch] = useState("");

  const [tableLoading, setTableLoading] = useState(true);
  const [selectedClinic, setSelectedClinic] = useState<number>(0);
  const [showArchived, setShowArchived] = useState(false);
  const [showStatsModal, setShowStatsModal] = useState(false);

  const getPractitionersBkp = () => {
    get<PractitionerList[]>("practitioner/getAll/")
      .then(r => {
        // console.log(r.data, "practitioners");
        setPractitioners(r.data);
      })
      .catch(_ => {})
      .finally(() => {});
  };
  const getPractitioners = () => {
    setTableLoading(true);
    get<PractitionerList[]>("practitioner/getByClinic/" + selectedClinic)
      .then(r => {
        // console.log(r.data, "practitioners");
        setPractitioners(r.data);
      })
      .catch(_ => {})
      .finally(() => {
        setTableLoading(false);
      });
  };
  const sendReminder = (id: number) => {
    post("practitioner/sendLoginReminder/", { id: id })
      .then(r => {})
      .catch(_ => {})
      .finally(() => {});
  };

  useEffect(() => {
    // console.log("user uf pract");
    // console.log(user?.clinics);
    getPractitioners();
  }, [selectedClinic]);

  useEffect(() => {
    // console.log("user uf pract");
    getPractitioners();
  }, [user]);

  const columns: ColumnsType<PractitionerList> = [
    // {
    //   title: t("created"),
    //   dataIndex: "created",
    //   key: "created",
    //   className: "pointer",
    //   sorter: (a, b) => a.created.localeCompare(b.created),
    //   render: date => <Text type="secondary">{formatDate(date, language)}</Text>
    // },
    {
      title: t("name"),
      key: "created",
      className: "pointer",
      sorter: (a, b) => a.created.localeCompare(b.first_name),
      render: (_, record) => (
        <Text className="tmain_col">
          {record.archived == true && (
            <>
              <FontAwesomeIcon icon={faTimes} className="red-color" />
              <> </>
            </>
          )}
          {record.first_name} {record.last_name}
          <br />
          <Text type="secondary">{record.email}</Text>
        </Text>
      )
    },
    {
      key: "last_login",
      className: "pointer",
      // sorter: (a, b) => a.email.localeCompare(b.last_login),
      render: (_, record) => (
        <>
          <Text type="secondary">
            <br />
            {t("pract.lastLogin")}:{" "}
            {record.last_login === null ? (
              <>
                {t("pract.neverLoggedIn")} <br />{" "}
                <Button
                  key="email_reminder_btn"
                  size="small"
                  type="primary"
                  onClick={e => {
                    sendReminder(record.id);
                    e.stopPropagation();
                  }}
                >
                  {t("pract.sendEmailReminder")}
                </Button>
              </>
            ) : (
              formatDate(record.last_login, language)
            )}
            <br />
            {t("pract.roleTitle")}: {t("pract.role.rl" + record.role)}
          </Text>
        </>
      ),
      responsive: ["md"]
    },
    {
      key: "actions",
      className: "pointer",
      render: (text, record) => (
        <Space.Compact style={{ float: "right" }} size="middle">
          <Button
            key="vtr"
            color="primary"
            variant="filled"
            onClick={e => {
              setPractitioner(record);
              setShowStatsModal(true);
              e.stopPropagation();
            }}
          >
            {t("pract.stats.title")}
          </Button>

          <Button
            key="etr"
            color="primary"
            variant="filled"
            hidden={record.role === UserRole.owner}
            onClick={e => {
              setPractitioner(record);
              setShowUpsertModal(true);

              e.stopPropagation();
            }}
          >
            {t("edit")}
          </Button>
          {record.archived == false &&
            record.role !== UserRole.owner &&
            record.last_login !== null && (
              <ConfirmButton
                key="archive"
                btnText={t("pract.archive")}
                message={t("pract.archiveMsg")}
                btnProps={{
                  block: true,
                  color: "red",
                  variant: "filled"
                }}
                onConfirm={() => {
                  get("practitioner/archive/" + record.id)
                    .then(() => {
                      getPractitioners();
                    })
                    .catch(_ => {});
                }}
              />
            )}
          {record.archived == false &&
            record.role !== UserRole.owner &&
            record.last_login === null && (
              <ConfirmButton
                key="delete"
                btnText={t("delete")}
                message={t("pract.deleteMsg")}
                btnProps={{ block: true, color: "red", variant: "filled" }}
                onConfirm={() => {
                  get("practitioner/delete/" + record.id)
                    .then(() => {
                      getPractitioners();
                    })
                    .catch(_ => {});
                }}
              />
            )}
          {record.archived == true && (
            <>
              {record.role !== UserRole.owner && record.last_login === null && (
                <ConfirmButton
                  key="delete"
                  btnText={t("delete")}
                  message={t("pract.deleteMsg")}
                  btnProps={{ block: true, color: "red", variant: "filled" }}
                  onConfirm={() => {
                    get("practitioner/delete/" + record.id)
                      .then(() => {
                        getPractitioners();
                      })
                      .catch(_ => {});
                  }}
                />
              )}
              <ConfirmButton
                key="activate"
                btnText={t("pract.activate")}
                message={t("pract.activateMsg")}
                btnProps={{
                  block: true,
                  color: "green",
                  variant: "filled"
                }}
                onConfirm={() => {
                  get("practitioner/activate/" + record.id)
                    .then(() => {
                      getPractitioners();
                    })
                    .catch(_ => {});
                }}
              />
            </>
          )}
        </Space.Compact>
      )
    }
  ];

  useEffect(() => {
    // console.log(user);
  }, [user, navigate]);

  const practitionersFiltered = practitioners.filter(
    entry =>
      String(entry.first_name).toLowerCase().includes(search.toLowerCase()) ||
      String(entry.last_name).toLowerCase().includes(search.toLowerCase()) ||
      String(entry.email).toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      {user && practitioners && (
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        >
          <Descriptions.Item label={t("account.activePractitioners")}>
            {practitioners.filter(p => !p.archived).length} (
            {t("account.outOf")} {user.allowed_practitioners_num})
          </Descriptions.Item>
          <Descriptions.Item label={t("account.archivedPractitioners")}>
            {practitioners.filter(p => p.archived).length}
          </Descriptions.Item>
        </Descriptions>
      )}
      <Space.Compact block>
        <Search
          key="search"
          placeholder={t("search")}
          onChange={e => setSearch(e.target.value)}
          enterButton={<FontAwesomeIcon icon={faSearch} />}
          allowClear
        />
        <Select
          onChange={setSelectedClinic}
          showSearch
          style={{ minWidth: 130 }}
          // ={user && user?.clinics.length < 2}
          value={selectedClinic}
          optionFilterProp="children"
          filterOption={true}
          // {...rest}
        >
          <Option value={0} key={0}>
            {t("allClinics")}
          </Option>
          {user &&
            user.clinics
              ?.filter(c => c.member_id == user.id)
              .map(c => (
                <Option value={c.id} key={c.id}>
                  {c.clinic_name}
                </Option>
              ))}
        </Select>
        <Button
          type={showArchived ? "primary" : "link"}
          onClick={() => setShowArchived(!showArchived)}
        >
          {t("showArchived")}
        </Button>
      </Space.Compact>
      {practitionersFiltered && (
        <>
          <Table
            loading={tableLoading}
            pagination={{
              position: ["bottomCenter"],
              hideOnSinglePage: true,
              pageSize: 100
            }}
            scroll={{ x: 500 }}
            dataSource={practitionersFiltered.filter(
              p => p.archived == showArchived
            )}
            columns={columns}
            rowKey="id"
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  if (record.role !== UserRole.owner) {
                    setPractitioner(record);
                    setShowUpsertModal(true);
                  }
                }
              };
            }}
          />
        </>
      )}

      {practitioner && (
        <>
          <UpsertPractitionerModal
            showModal={showUpsertModal}
            practitioner={practitioner}
            onDone={() => {
              getPractitioners();
              setPractitioner(newPractitionerData);
              setShowUpsertModal(false);
            }}
            onCancel={() => {
              setPractitioner(newPractitionerData);
              setShowUpsertModal(false);
            }}
          />
          <PractitionerStatsModal
            showModal={showStatsModal}
            practitioner={practitioner}
            onDone={() => {
              setShowStatsModal(false);
            }}
            onCancel={() => {
              setShowStatsModal(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default Practitioners;
