import React, { useState, useContext, useEffect } from "react";
import enGB from "antd/lib/locale/en_GB";
import plPl from "antd/lib/locale/pl_PL";
import frFR from "antd/lib/locale/fr_FR";
import esES from "antd/lib/locale/es_ES";
import fiFI from "antd/lib/locale/fi_FI";
import hiIN from "antd/lib/locale/hi_IN";
import itIT from "antd/lib/locale/it_IT";
import ptPT from "antd/lib/locale/pt_PT";
import arEg from "antd/lib/locale/ar_EG";
import neNp from "antd/lib/locale/ne_NP";
import ruRu from "antd/lib/locale/ru_RU";
import deDe from "antd/lib/locale/de_DE";
import jaJP from "antd/lib/locale/ja_JP";
import nlNL from "antd/lib/locale/nl_NL";
import bnBD from "antd/lib/locale/bn_BD";
import idID from "antd/lib/locale/id_ID";
import zhCN from "antd/lib/locale/zh_CN";
import urPK from "antd/lib/locale/ur_PK";
import svSE from "antd/lib/locale/sv_SE";
import taIN from "antd/lib/locale/ta_IN";
import trTR from "antd/lib/locale/tr_TR";
import viVN from "antd/lib/locale/vi_VN";
import { Language } from "../types/General";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Locale } from "antd/lib/locale";

interface ContextProps {
  isBigScreen?: boolean;
  isTabletOrMobile?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
  isPortrait?: boolean;
  isRetina?: boolean;
  isIos?: boolean;
  language: Language;
  languageAntd: Locale;
  setLanguage: (language: Language) => void;
}

const getLanguage = () => {
  let langFromUrl: Language = Language.enGB;
  const currentURL = window.location.href;
  if (currentURL.includes("loc.")) langFromUrl = Language.plPl;

  if (localStorage.getItem("language") !== null)
    return localStorage.getItem("language") as Language;
  else return langFromUrl;
};

const getI18nLanguageKey = (lang: Language) => {
  switch (lang) {
    case Language.enGB:
      return "en_GB";
    case Language.frFR:
      return "fr";
    case Language.arAr:
      return "ar";
    case Language.neNe:
      return "ne";
    case Language.deDe:
      return "de";
    case Language.ruRu:
      return "ru";
    case Language.plPl:
      return "pl";
    case Language.elEl:
      return "el";
    case Language.esES:
      return "es";
    case Language.fiFI:
      return "fi";
    case Language.hiIN:
      return "hi";
    case Language.itIT:
      return "it";
    case Language.ptPT:
      return "pt";
    case Language.jaJa:
      return "ja";
    case Language.nlNl:
      return "nl";
    case Language.bnBn:
      return "bn";
    case Language.idId:
      return "id";
    case Language.zhCn:
      return "zh";
    case Language.urUr:
      return "ur";
    case Language.paPa:
      return "pa";
    case Language.haNg:
      return "ha";
    case Language.mrMr:
      return "mr";
    case Language.noNo:
      return "no";
    case Language.svSv:
      return "sv";
    case Language.taTa:
      return "ta";
    case Language.teTe:
      return "te";
    case Language.trTr:
      return "tr";
    case Language.viVi:
      return "vi";
    case Language.huHu:
      return "hu";
    case Language.etEt:
      return "et";
    case Language.guGu:
      return "gu";
    case Language.thTh:
      return "th";
    case Language.koKo:
      return "ko";
    case Language.zhTw:
      return "zh_TW";

    default:
      return "en_GB";
  }
};

const getAntdLanguageVariable = (lang: Language) => {
  switch (lang) {
    case Language.enGB:
      return enGB;
    case Language.frFR:
      return frFR;
    case Language.arAr:
      return arEg;
    case Language.neNe:
      return neNp;
    case Language.deDe:
      return deDe;
    case Language.ruRu:
      return ruRu;
    case Language.plPl:
      return plPl;
    case Language.esES:
      return esES;
    case Language.fiFI:
      return fiFI;
    case Language.hiIN:
      return hiIN;
    case Language.itIT:
      return itIT;
    case Language.ptPT:
      return ptPT;
    case Language.jaJa:
      return jaJP;
    case Language.nlNl:
      return nlNL;
    case Language.bnBn:
      return bnBD;
    case Language.idId:
      return idID;
    case Language.zhCn:
      return zhCN;
    case Language.urUr:
      return urPK;
    case Language.paPa:
      return enGB;
    case Language.haNg:
      return enGB;
    case Language.mrMr:
      return enGB;
    case Language.noNo:
      return enGB;
    case Language.svSv:
      return svSE;
    case Language.taTa:
      return taIN;
    case Language.teTe:
      return enGB;
    case Language.trTr:
      return trTR;
    case Language.viVi:
      return viVN;
    default:
      return enGB;
  }
};

export const GlobalContext = React.createContext<ContextProps>({
  language: getLanguage(),
  languageAntd: getAntdLanguageVariable(getLanguage()),
  setLanguage: () => {}
});

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export const isIOS = () => {
  let platform = navigator?.userAgent || navigator?.platform || "unknown";
  return /iPhone|iPod|iPad/.test(platform);
};

export default function GlobalProvider({ children }) {
  const { i18n } = useTranslation("common");
  const [languageAntd, setLanguageAntd] = useState(
    getAntdLanguageVariable(getLanguage())
  );

  const [language, setLanguage] = useState<Language>(getLanguage());

  useEffect(() => {
    setLanguageAntd(getAntdLanguageVariable(getLanguage()));
    i18n.changeLanguage(getI18nLanguageKey(getLanguage()));
  }, []);

  const updateLanguage = (lang: Language) => {
    localStorage.setItem("language", lang);

    setLanguage(lang);
    // console.log("setting languages", lang);
    setLanguageAntd(getAntdLanguageVariable(lang));
    i18n.changeLanguage(getI18nLanguageKey(lang));
    // todo update user data via API
  };

  const contextValue: ContextProps = {
    isBigScreen: useMediaQuery({ query: "(min-width: 1600px)" }),
    isTabletOrMobile: useMediaQuery({ query: "(max-width: 992px)" }),
    isMobile: useMediaQuery({ query: "(max-width: 567px)" }),
    isTablet: useMediaQuery({
      query: "(min-width: 567px) and (max-width: 992px)"
    }),
    isPortrait: useMediaQuery({ query: "(orientation: portrait)" }),
    isRetina: useMediaQuery({ query: "(min-resolution: 2dppx)" }),
    isIos: isIOS(),
    language: language,
    languageAntd: languageAntd,
    setLanguage: lang => {
      updateLanguage(lang);
    }
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
}
