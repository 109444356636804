import React, { useRef, useState } from "react";
import { Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";
import UpsertPatientModal from "../../pages/patients/UpsertPatientModal";
import { ImageType } from "../../types/Plan";
import type { MenuProps } from "antd";

type MenuItem = Required<MenuProps>["items"][number];

const MainMenu: React.FC = () => {
  const [showPatientModal, setShowPatientModal] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();

  const myPatientsLink = useRef<HTMLAnchorElement>(null);
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem(
      <Link to="/exercise-plan?id=0&mode=plan">{t("plan.createNewPlan")}</Link>,
      "create-plan"
    ),
    getItem(
      <span
        onClick={() => {
          myPatientsLink.current?.click();
        }}
      >
        {t("menu.myPatients")}
      </span>,
      "my_patients",
      null,
      [
        getItem(
          <Link to="/patients" ref={myPatientsLink}>
            {t("menu.viewMyPatients")}
          </Link>,
          "my_patients2"
        ),
        getItem(
          <span
            onClick={e => {
              setShowPatientModal(true);
              e.stopPropagation();
            }}
          >
            {t("menu.addNewPatient")}
          </span>,
          "new_patient"
        )
      ]
    ),

    getItem(<Link to="/templates">{t("menu.templates")}</Link>, "templates"),
    getItem(
      <Link to="/my-exercises">{t("menu.myExercises")}</Link>,
      "myexercises"
    ),
    getItem(<Link to="/telerehab">{t("menu.telerehab")}</Link>, "telerehab"),
    getItem(<Link to="/stats">{t("menu.stats")}</Link>, "stats"),
    getItem(<Link to="/contact">{t("menu.contact")}</Link>, "contact")
  ];

  if (user === null) {
    items.push(getItem(<Link to="/login">{t("login")}</Link>, "login"));
  }

  return (
    <div className="wrapper">
      <Logo />
      <Menu
        items={items}
        theme="dark"
        mode="horizontal"
        triggerSubMenuAction="hover"
      />
      {/* Remove or comment out the second Menu component */}
      {/* <Menu theme="dark" mode="horizontal" triggerSubMenuAction="hover"> ... </Menu> */}

      {user && user.settings && (
        <UpsertPatientModal
          initialData={{
            id: 0,
            first_name: "",
            last_name: "",
            send_login_details: true,
            patient_consent: true,
            phone: "",
            dial_code: user?.settings.default_phone_dial_code,
            image_type: ImageType.photo
          }}
          showModal={showPatientModal}
          onDone={id => {
            setShowPatientModal(false);
            navigate("/patient/" + id);
          }}
          onDoneAndCNP={id => {
            setShowPatientModal(false);
            navigate("/exercise-plan?patient=" + id);
          }}
          onCancel={() => {
            setShowPatientModal(false);
          }}
        />
      )}
    </div>
  );
};

export default MainMenu;
