import React, { useEffect, useState } from "react";
import { PlanViewDTO } from "../../types/Plan";
import { useAuth } from "../../context/AuthContext";
import { Column } from "@ant-design/plots";
import { get } from "../../api/Request";
import { TrackingResult } from "../../types/Tracking";
import { getPatientSalutation } from "../../helpers/helper";
import { useTranslation } from "react-i18next";
import { Result } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Patient2 } from "../../types/Patient";
import ValueLabel from "../../components/layout/ValueLabel";
import Paragraph from "antd/lib/typography/Paragraph";

interface Props {
  plan: PlanViewDTO;
  patient: Patient2;
}
const PlanTrackingResult: React.FC<Props> = ({ plan, patient }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>();
  const [done, setDone] = useState<any>();
  const [liked, setLiked] = useState<any>();

  const getData = () => {
    get<TrackingResult[]>("tracking/trackingChartData/" + plan.plan.id)
      .then(r => {
        // console.log(r.data, "tracking data");
        setData(r.data);
      })
      .catch(e => {});
  };

  const getExerciseNames = (logs: any[], filterFn: (log: any) => boolean) => {
    return logs.filter(filterFn).map(log => {
      const exercise = plan.exercises.find(e => e.id === log.exercise_id);
      return exercise ? exercise.title : `#${log.exercise_id}`;
    }); // Removed .join(", ") to return array instead of string
  };

  useEffect(() => {
    getData();
  }, [plan]);
  useEffect(() => {
    if (data !== undefined && data.length > 0 && user) {
      const done = data.map(d => {
        return {
          type: t("plan.exercisesDone"),
          date: d.date,
          value: d.data !== false ? d.data.done : null,
          exerciseNames:
            d.data !== false
              ? getExerciseNames(d.data.logs, log => log.done === 1)
              : ""
        };
      });

      const not_done = data.map(d => {
        return {
          type: t("plan.exercisesNotDone"),
          date: d.date,
          value: d.data !== false ? d.data.not_done : null,
          exerciseNames:
            d.data !== false
              ? getExerciseNames(d.data.logs, log => log.done === 0)
              : ""
        };
      });

      var doneNotDone = {
        data: [...done, ...not_done],
        isStack: true,
        xField: "date",
        yField: "value",
        seriesField: "type",
        // Add custom tooltip
        tooltip: {
          customContent: (title, items) => {
            return (
              <div style={{ padding: 8 }}>
                <h4>{title}</h4>
                {items.map((item, index) => {
                  const { value, color, data } = item;
                  return (
                    <div
                      key={index}
                      style={{
                        color: color,
                        marginBottom: 8
                      }}
                    >
                      {value > 0 && (
                        <>
                          <span style={{ fontWeight: 500 }}>{data.type}:</span>
                          {data.exerciseNames &&
                            data.exerciseNames.length > 0 && (
                              <ul
                                style={{
                                  fontSize: "12px",
                                  margin: "4px 0",
                                  paddingLeft: 20,
                                  listStyleType: "none"
                                }}
                              >
                                {data.exerciseNames.map(
                                  (name: string, i: number) => (
                                    <li key={i}>{name}</li>
                                  )
                                )}
                              </ul>
                            )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          }
        },
        xAxis: {
          label: {
            formatter: (text: string) => text
          }
        },
        slider: {
          start: 0,
          end: 1, // Changed from: end: done.length > 10 ? 0.5 : 1,
          formatter: (v: number) => {
            const index = Math.floor(v * (done.length - 1));
            return done[index]?.date || "";
          },
          ticks: [0, 0.25, 0.5, 0.75, 1],
          minLimit: 0,
          maxLimit: 1
        },
        label: {
          position: "middle", // 'top', 'bottom', 'middle'

          layout: [
            { type: "interval-adjust-position" },

            { type: "interval-hide-overlap" },

            { type: "adjust-color" }
          ]
        },

        colorField: "type", // or seriesField in some cases
        color: ({ type }) => {
          if (type === t("plan.exercisesNotDone")) {
            return "red";
          }
          return "green";
        },
        legend: false // Add this line to remove the legend
      };
      setDone(doneNotDone);

      const liked = data.map(d => {
        return {
          type: t("plan.exercisesLiked"),
          date: d.date,
          value: d.data !== false ? d.data.like : null,
          exerciseNames:
            d.data !== false
              ? getExerciseNames(d.data.logs, log => log.like === 1)
              : ""
        };
      });

      const not_liked = data.map(d => {
        return {
          type: t("plan.exercisesNotLiked"),
          date: d.date,
          value: d.data !== false ? d.data.dislike : null,
          exerciseNames:
            d.data !== false
              ? getExerciseNames(d.data.logs, log => log.dislike === 1)
              : ""
        };
      });

      var likedNotLiked = {
        data: [...liked, ...not_liked],
        isStack: true,
        xField: "date",
        yField: "value",
        seriesField: "type",
        // Add custom tooltip
        tooltip: {
          customContent: (title, items) => {
            return (
              <div style={{ padding: 8 }}>
                <h4>{title}</h4>
                {items.map((item, index) => {
                  const { value, color, data } = item;
                  return (
                    <div
                      key={index}
                      style={{
                        color: color,
                        marginBottom: 8
                      }}
                    >
                      {value > 0 && (
                        <>
                          <span style={{ fontWeight: 500 }}>{data.type}:</span>
                          {data.exerciseNames &&
                            data.exerciseNames.length > 0 && (
                              <ul
                                style={{
                                  fontSize: "12px",
                                  margin: "4px 0",
                                  paddingLeft: 20,
                                  listStyleType: "none"
                                }}
                              >
                                {data.exerciseNames.map(
                                  (name: string, i: number) => (
                                    <li key={i}>{name}</li>
                                  )
                                )}
                              </ul>
                            )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          }
        },
        xAxis: {
          label: {
            formatter: (text: string) => text
          }
        },
        slider: {
          start: 0,
          end: 1, // Changed from: end: liked.length > 10 ? 0.5 : 1,
          formatter: (v: number) => {
            const index = Math.floor(v * (liked.length - 1));
            return liked[index]?.date || "";
          },
          ticks: [0, 0.25, 0.5, 0.75, 1],
          minLimit: 0,
          maxLimit: 1
        },
        label: {
          position: "middle", // 'top', 'bottom', 'middle'

          layout: [
            { type: "interval-adjust-position" },

            { type: "interval-hide-overlap" },

            { type: "adjust-color" }
          ]
        },
        colorField: "type", // or seriesField in some cases
        color: ({ type }) => {
          if (type === t("plan.exercisesNotLiked")) {
            return "red";
          }
          return "green";
        },
        legend: false // Add this line to remove the legend
      };
      setLiked(likedNotLiked);
    }
  }, [data, plan.exercises, user?.practitioner?.lang]);

  return (
    <>
      {user && data !== undefined && data.length > 0 && (
        <div style={{ textAlign: "center" }}>
          <h3>{t("plan.exercisesDone")}</h3>
          {done && <Column {...done} />}
          <h3>{t("plan.exercisesLiked")}</h3>
          {liked && (
            <Column
              {...liked}
              onReady={plot => {
                plot.on("plot:click", evt => {
                  const { x, y } = evt;
                  const { xField } = plot.options;
                  const tooltipData = plot.chart.getTooltipItems({ x, y });
                  // console.log(tooltipData);
                });
              }}
            />
          )}
        </div>
      )}

      {data !== undefined && data.length === 0 && (
        <Result
          style={{
            background: "#f0f0f0",
            borderRadius: 8,
            margin: "16px"
          }}
          icon={<FontAwesomeIcon icon={faChartLine} size="4x" />}
          title={t("plan.noTrackRehabRes")}
          subTitle={t("plan.noTrackRehabRes2")}
          extra={
            <>
              <h3>{getPatientSalutation(patient)}</h3>
              <ValueLabel label={t("patient.trAccessCodeLabel")}>
                <Paragraph
                  copyable={{
                    text: "https://trackrehab.com/c/" + patient.access_code,
                    tooltips: [t("copy"), t("copied")]
                  }}
                >
                  {patient.access_code}
                </Paragraph>
              </ValueLabel>
            </>
          }
        />
      )}
    </>
  );
};

export default PlanTrackingResult;
