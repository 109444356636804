// File: BodyChartUpsertModal.js

import { useEffect, useRef, useState } from "react";
import { Button, Select, Space, ColorPicker } from "antd";
import { useTranslation } from "react-i18next";
import { fabric } from "fabric"; // Import fabric.js
import MyModal from "../../components/MyModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faHandPointer,
  faSquare
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRightLong,
  faFont,
  faPencil,
  faUpDownLeftRight,
  faSearchMinus,
  faSearchPlus
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { config } from "../../data/Constants";
import { generate, presetPalettes } from "@ant-design/colors";
import { useGlobalContext } from "../../context/GlobalContext";

const BodyChartUpsertModal = ({ id, json, showModal, onCancel, onDone }) => {
  const { t } = useTranslation();

  const canvasRef = useRef(null);
  const { isMobile } = useGlobalContext();

  const [modalSize, setModalSize] = useState(1150);

  const [isDrawing, setIsDrawing] = useState(false);
  const [tool, setTool] = useState("SELECT");
  const [shape, setShape] = useState(null);
  const [color, setColor] = useState("#ff0000");
  const [originX, setOriginX] = useState(0);
  const [originY, setOriginY] = useState(0);

  const [strokeWidth, setStrokeWidth] = useState(2);

  const [textSelected, setTextSelected] = useState(false);
  const [selectionCount, setSelectionCount] = useState(0);
  const [isArrowSelected, setIsArrowSelected] = useState(false);
  const [isColorPopupOpen, setIsColorPopupOpen] = useState(false);

  const [zoomLevel, setZoomLevel] = useState(1);
  const minZoom = 1;
  const maxZoom = 3;

  const [isCanvasTransformed, setIsCanvasTransformed] = useState(false);

  const imageOptions = [
    config.OLD_SITE_URL + "assets/rmp/img/body_chart/body-chart-lineart.jpg",
    config.OLD_SITE_URL + "assets/rmp/img/body_chart/body-chart-man.jpg"
  ];
  const [selectedImage, setSelectedImage] = useState(imageOptions[0]);
  const loadSelectedImage = url => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;

    fabric.Image.fromURL(
      url,
      img => {
        // Set background image properties
        img.set({
          selectable: false // Background image should not be selectable
        });

        // Set the background image on the canvas
        canvas.setBackgroundImage(
          img,
          canvas.renderAll.bind(canvas), // Ensure canvas re-renders after setting the background
          {
            backgroundImageOpacity: 1, // Full opacity
            originX: "left", // Align image from the left
            originY: "top" // Align image from the top
          }
        );

        // Resize the canvas to match the image dimensions
        canvas.setWidth(img.width);
        setModalSize(img.width + 50);
        canvas.setHeight(img.height);
        resetPanning();
        canvas.renderAll(); // Render the canvas
      },
      { crossOrigin: "anonymous" }
    );
  };

  useEffect(() => {
    if (showModal) {
      resetCanvas(); // Reset the canvas for a fresh start

      canvasRef.current = new fabric.Canvas("fabric-canvas", {
        // width: 800,
        // height: 600,
        // backgroundColor: "#f5f5f5"
      });

      if (json) {
        canvasRef.current.clear();
        canvasRef.current.loadFromJSON(json.json, () => {
          canvasRef.current.renderAll();
        });

        let data = JSON.parse(json.json);
        if (data.backgroundImage) {
          setSelectedImage(data.backgroundImage.src);
          loadSelectedImage(data.backgroundImage.src);
        }
      } else {
        canvasRef.current.clear();

        setSelectedImage(imageOptions[0]);
        loadSelectedImage(imageOptions[0]);
      }

      canvasRef.current.freeDrawingBrush.limitedToCanvasSize = true;

      enableFreeDrawing();

      return () => cleanupCanvas(); // Cleanup when modal is closed
    }
  }, [showModal]);

  const resetCanvas = () => {
    if (canvasRef.current) {
      canvasRef.current.clear(); // Clear all objects
      canvasRef.current.setBackgroundImage(
        null,
        canvasRef.current.renderAll.bind(canvasRef.current)
      ); // Remove background image
    }
  };

  const cleanupCanvas = () => {
    if (canvasRef.current) {
      canvasRef.current.dispose(); // Dispose canvas instance
      canvasRef.current = null; // Remove reference
    }
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === "Delete" && canvasRef.current) {
        deleteSelectedObject();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [canvasRef.current]);

  useEffect(() => {
    setIsArrowSelected(false);
    if (canvasRef.current) {
      if (tool === "SELECT") {
        // Disable drawing mode
        canvasRef.current.isDrawingMode = false;

        // Enable selection and movement of objects
        canvasRef.current.selection = true;

        // Ensure all objects are selectable
        canvasRef.current.getObjects().forEach(obj => {
          obj.selectable = true;
        });
      }
      if (tool !== "FREE_DRAW") {
        canvasRef.current.isDrawingMode = false;
      }
      if (tool !== "SELECT") {
        canvasRef.current.discardActiveObject(); // Clear the active selection
        canvasRef.current.getObjects().forEach(obj => {
          obj.set({ selectable: false });
        });
        canvasRef.current.renderAll(); // Re-render the canvas to reflect the changes
        setSelectionCount(0); // Reset selection count
      }
      if (tool === "SELECT") {
        canvasRef.current.getObjects().forEach(obj => {
          obj.selectable = true;
        });
      }
      if (tool !== "TEXT" && tool !== "SELECT") {
        setTextSelected(false); // Reset text selection state
      }
    }
  }, [tool]);

  useEffect(() => {
    if (canvasRef.current === null) {
      return;
    }

    let isPanning = false;
    let startPointer = { x: 0, y: 0 };

    const handleMouseDown = event => {
      // Enable panning for the "SELECT" mode or any specific mode
      if (isColorPopupOpen) {
        setIsColorPopupOpen(false);
      }
      if (tool === "PAN") {
        isPanning = true;
        startPointer = canvasRef.current.getPointer(event.e);
        canvasRef.current.setCursor("grab");
      } else {
        switch (tool) {
          case "CIRCLE":
            handleMouseDownCircle(event);
            break;
          case "RECT":
            handleMouseDownRect(event);
            break;
          case "LINE":
            handleMouseDownLine(event);
            break;
          case "ARROW":
            handleMouseDownArrow(event);
            break;
          case "TEXT":
            handleMouseDownText(event);
            break;
          default:
            break;
        }
      }
    };

    const handleMouseMove = event => {
      if (tool === "PAN" && isPanning) {
        const pointer = canvasRef.current.getPointer(event.e);
        const deltaX = pointer.x - startPointer.x;
        const deltaY = pointer.y - startPointer.y;

        const currentViewport = canvasRef.current.viewportTransform;
        if (currentViewport) {
          const newLeftEdge = currentViewport[4] + deltaX;

          // Prevent panning beyond the left edge
          if (newLeftEdge <= 0) {
            canvasRef.current.relativePan({ x: deltaX, y: deltaY });
            startPointer = pointer;
            setIsCanvasTransformed(
              zoomLevel !== 1 ||
                currentViewport[4] !== 0 ||
                currentViewport[5] !== 0
            );
          }
        }
      } else {
        switch (tool) {
          case "CIRCLE":
            handleMouseMoveCircle(event);
            break;
          case "RECT":
            handleMouseMoveRect(event);
            break;
          case "LINE":
            handleMouseMoveLine(event);
            break;

          default:
            break;
        }
      }
    };

    const handleMouseUp = () => {
      if (isPanning) {
        isPanning = false;
        canvasRef.current.setCursor("default");
      } else {
        if (tool === "FREE_DRAW") {
          // Handle free draw
        } else {
          canvasRef.current.isDrawingMode = false;
          setIsDrawing(false);

          // Make the last added object selectable
          if (shape) {
            shape.set({ selectable: true });
          }

          setShape(null);
          setTool("SELECT");

          // Enable selection for all objects
          canvasRef.current.getObjects().forEach(obj => {
            obj.set({ selectable: true });
          });
          canvasRef.current.renderAll();
        }
      }
    };

    canvasRef.current.on("mouse:down", handleMouseDown);
    canvasRef.current.on("mouse:move", handleMouseMove);
    canvasRef.current.on("mouse:up", handleMouseUp);

    const handleSelection = event => {
      const activeObject = event.selected;

      setSelectionCount(activeObject.length);
      if (activeObject.length === 0) setIsArrowSelected(false);

      if (activeObject && activeObject.length === 1) {
        // Check if selected object is an arrow group
        setIsArrowSelected(activeObject[0].customType === "lineArrowGroup");

        if (activeObject[0].text) {
          setTextSelected(true);
          setColor(activeObject[0].fill);
        } else {
          setTextSelected(false);
          // Update color based on object type
          if (activeObject[0].customType === "lineArrowGroup") {
            // For arrow groups, update all child objects
            activeObject[0]._objects.forEach(child => {
              if (child.customType === "lineArrow") {
                setColor(child.stroke);
                // setStrokeWidth(child.strokeWidth);
              }
            });
          } else {
            setColor(activeObject[0].stroke);
            setStrokeWidth(activeObject[0].strokeWidth);
          }
        }
      }
    };

    const handleDeselection = () => {
      setSelectionCount(0);
      setTextSelected(false);
      setIsArrowSelected(false); // Reset arrow selection state
    };

    // Attach event listeners
    canvasRef.current.on("selection:created", handleSelection);
    canvasRef.current.on("selection:updated", handleSelection);
    canvasRef.current.on("selection:cleared", handleDeselection);

    return () => {
      // Clean up event listeners
      if (canvasRef.current) {
        canvasRef.current.off("mouse:down", handleMouseDown);
        canvasRef.current.off("mouse:move", handleMouseMove);
        canvasRef.current.off("mouse:up", handleMouseUp);
        canvasRef.current.off("selection:created", handleSelection);
        canvasRef.current.off("selection:updated", handleSelection);
        canvasRef.current.off("selection:cleared", handleDeselection);
      }
    };
  }, [tool, isDrawing, shape, originX, originY, canvasRef, color, strokeWidth]);

  const resetPanning = () => {
    if (canvasRef.current) {
      canvasRef.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
      setZoomLevel(1);
      setIsCanvasTransformed(false);
      canvasRef.current.renderAll();
    }
  };

  const handleZoom = delta => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    let newZoom = zoomLevel + delta;

    // Only allow zoom changes if:
    // 1. Zooming in (delta > 0) OR
    // 2. Zooming out (delta < 0) but won't go below 100%
    if (delta > 0 || (delta < 0 && newZoom >= minZoom)) {
      // Constrain zoom level
      newZoom = Math.min(Math.max(newZoom, minZoom), maxZoom);

      // Get the center point of the canvas
      const center = {
        x: canvas.width / 2,
        y: canvas.height / 2
      };

      canvas.zoomToPoint({ x: center.x, y: center.y }, newZoom);
      setZoomLevel(newZoom);
      setIsCanvasTransformed(
        newZoom !== 1 ||
          canvas.viewportTransform[4] !== 0 ||
          canvas.viewportTransform[5] !== 0
      );
      canvas.renderAll();
    }
  };

  useEffect(() => {
    if (!canvasRef.current) return;

    const handleWheel = event => {
      if (!event.ctrlKey) return;
      event.preventDefault();

      const delta = event.deltaY < 0 ? 0.1 : -0.1;
      const newZoom = zoomLevel + delta;

      // Only proceed if we're not zooming out below 100%
      if (delta > 0 || (delta < 0 && newZoom >= minZoom)) {
        const pointer = canvasRef.current.getPointer(event.e);
        const constrainedZoom = Math.min(Math.max(newZoom, minZoom), maxZoom);
        canvasRef.current.zoomToPoint(pointer, constrainedZoom);
        setZoomLevel(constrainedZoom);
        setIsCanvasTransformed(
          constrainedZoom !== 1 ||
            canvasRef.current.viewportTransform[4] !== 0 ||
            canvasRef.current.viewportTransform[5] !== 0
        );
      }
    };

    canvasRef.current.on("mouse:wheel", handleWheel);

    return () => {
      if (canvasRef.current) {
        canvasRef.current.off("mouse:wheel", handleWheel);
      }
    };
  }, [zoomLevel]);

  // Circle Handlers
  const handleMouseDownCircle = event => {
    const pointer = canvasRef.current.getPointer(event.e);
    const newCircle = new fabric.Circle({
      left: pointer.x,
      top: pointer.y,
      originX: "center",
      originY: "center",
      radius: 0,
      fill: "",
      stroke: color,
      strokeWidth: strokeWidth,
      selectable: true,
      strokeUniform: true
    });
    setOriginX(pointer.x);
    setOriginY(pointer.y);

    canvasRef.current.add(newCircle);
    setShape(newCircle);
    setIsDrawing(true);
  };

  const handleMouseMoveCircle = event => {
    if (isDrawing && shape) {
      const pointer = canvasRef.current.getPointer(event.e);
      const radius = Math.hypot(pointer.x - originX, pointer.y - originY);
      shape.set({ radius });
      canvasRef.current.renderAll();
    }
  };

  // Rectangle Handlers
  const handleMouseDownRect = event => {
    const pointer = canvasRef.current.getPointer(event.e);
    setOriginX(pointer.x);
    setOriginY(pointer.y);
    const newRect = new fabric.Rect({
      left: pointer.x,
      top: pointer.y,
      originX: "left",
      originY: "top",
      width: 0,
      height: 0,
      fill: "",
      stroke: color,
      strokeWidth: strokeWidth,
      selectable: true,
      strokeUniform: true
    });
    canvasRef.current.add(newRect);
    setShape(newRect);
    setIsDrawing(true);
  };

  const handleMouseMoveRect = event => {
    if (isDrawing && shape) {
      const pointer = canvasRef.current.getPointer(event.e);
      shape.set({
        width: Math.abs(originX - pointer.x),
        height: Math.abs(originY - pointer.y)
      });
      if (originX > pointer.x) shape.set({ left: pointer.x });
      if (originY > pointer.y) shape.set({ top: pointer.y });
      canvasRef.current.renderAll();
    }
  };

  // Line Handlers
  const handleMouseDownLine = event => {
    const pointer = canvasRef.current.getPointer(event.e);
    const newLine = new fabric.Line(
      [pointer.x, pointer.y, pointer.x, pointer.y],
      {
        stroke: color,
        strokeWidth: strokeWidth,
        strokeUniform: true,
        selectable: true
      }
    );
    canvasRef.current.add(newLine);
    setShape(newLine);
    setIsDrawing(true);
  };

  const handleMouseMoveLine = event => {
    if (isDrawing && shape) {
      const pointer = canvasRef.current.getPointer(event.e);
      shape.set({ x2: pointer.x, y2: pointer.y });
      canvasRef.current.renderAll();
    }
  };
  // Arrow Handlers
  const handleMouseDownArrow = event => {
    const pointer = canvasRef.current.getPointer(event.e);
    const x = pointer.x;
    const y = pointer.y;

    // Define the length of the arrow with minimum value of 50
    const arrowLength = Math.max(50, strokeWidth * 5);

    // Calculate the start point of the arrow at a 45 degree angle
    const x1 = x - arrowLength * Math.cos(Math.PI / 4);
    const y1 = y + arrowLength * Math.sin(Math.PI / 4);

    const line = new fabric.Line([x1, y1, x, y], {
      stroke: color,
      strokeWidth: strokeWidth,
      selectable: true,
      customType: "lineArrow"
    });

    const triangle = new fabric.Triangle({
      left: x + strokeWidth,
      top: y,
      originX: "center",
      originY: "center",
      angle: 45,
      width: Math.max(10, strokeWidth * 2) + strokeWidth,
      height: Math.max(10, strokeWidth * 2) + strokeWidth,
      fill: color,
      stroke: "transparent",
      selectable: true,
      customType: "lineArrowHead"
    });

    const group = new fabric.Group([line, triangle], {
      selectable: true,
      customType: "lineArrowGroup"
    });

    canvasRef.current.add(group);
    canvasRef.current.renderAll();
  };

  const handleMouseDownText = event => {
    const pointer = canvasRef.current.getPointer(event.e);
    const newText = new fabric.Textbox("Your text here", {
      left: pointer.x,
      top: pointer.y,
      fill: color,
      fontSize: 18, // Use fixed font size instead of textSize
      width: 100,
      selectable: true, // Allow text to be selected
      lockScalingFlip: true, // Prevent flipping while scaling
      lockUniScaling: true // Lock aspect ratio during scaling
    });

    canvasRef.current.add(newText); // Add text to canvas
    canvasRef.current.setActiveObject(newText); // Make the new text active
    newText.selectAll(); // Automatically select all text
    canvasRef.current.requestRenderAll(); // Re-render canvas to reflect changes
    setTextSelected(true); // Set text selection state to true
    setShape(newText);
  };

  const enableFreeDrawing = () => {
    if (canvasRef.current) {
      canvasRef.current.isDrawingMode = true;
      setTool("FREE_DRAW");
      // Dynamically set the drawing tool's color and width
      const brush = canvasRef.current.freeDrawingBrush;
      if (brush) {
        brush.color = color; // Use the selected stroke color
        brush.width = strokeWidth; // Use the selected stroke width
      }
    }
  };
  // Delete Selected Objects
  const deleteSelectedObject = () => {
    const activeObjects = canvasRef.current.getActiveObjects(); // Get all selected objects
    if (activeObjects.length) {
      activeObjects.forEach(object => {
        canvasRef.current.remove(object); // Remove each object
      });
      canvasRef.current.discardActiveObject(); // Clear selection after deletion
      canvasRef.current.renderAll(); // Re-render the canvas
    }
  };

  // Submit Function
  const submit = () => {
    if (canvasRef.current) {
      const jsonData = JSON.stringify(canvasRef.current.toJSON(["customType"]));
      setIsColorPopupOpen(false);
      onDone({ json: jsonData, id: id === 0 ? uuidv4() : id });
    }
  };

  // COLOR effect
  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const activeObjects = canvas.getActiveObjects();

    // Update color for selected objects
    activeObjects.forEach(object => {
      if (object.customType === "lineArrowGroup") {
        object._objects.forEach(child => {
          if (child.customType === "lineArrow") {
            child.set({ stroke: color });
          } else if (child.customType === "lineArrowHead") {
            child.set({ fill: color });
          }
        });
      } else if (
        object.customType === "lineArrow" ||
        object.customType === "lineArrowHead"
      ) {
        object.set({
          fill: color,
          stroke: color
        });
      } else if (object.type === "textbox") {
        object.set({ fill: color });
      } else {
        // Non-text and non-arrow object
        object.set({ stroke: color });
      }
    });

    // Update free drawing brush for color
    if (tool === "FREE_DRAW" && canvas.freeDrawingBrush) {
      canvas.freeDrawingBrush.color = color;
    }

    canvas.renderAll();
  }, [color]);

  // STROKE effect
  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const activeObjects = canvas.getActiveObjects();

    // Update stroke width for selected objects
    activeObjects.forEach(object => {
      if (object.customType === "lineArrowGroup") {
        object._objects.forEach(child => {
          if (child.customType === "lineArrow") {
            child.set({ strokeWidth });
          }
        });
      } else if (
        object.customType === "lineArrow" ||
        object.customType === "lineArrowHead"
      ) {
        object.set({ strokeWidth });
      } else if (object.type !== "textbox") {
        // Non-text object (rectangle, circle, line, etc.)
        object.set({ strokeWidth });
      }
      // Typically no stroke width needed for textbox
    });

    // Update free drawing brush for stroke width
    if (tool === "FREE_DRAW" && canvas.freeDrawingBrush) {
      canvas.freeDrawingBrush.width = strokeWidth;
    }

    canvas.renderAll();
  }, [strokeWidth]);

  // TOOL effect (optional if you want to separate this logic as well)
  useEffect(() => {
    if (!canvasRef.current) return;

    if (tool === "SELECT") {
      canvasRef.current.isDrawingMode = false;
      canvasRef.current.selection = true;
      canvasRef.current.getObjects().forEach(obj => {
        obj.set({ selectable: true });
      });
    } else if (tool === "FREE_DRAW") {
      canvasRef.current.isDrawingMode = true;
    } else {
      canvasRef.current.isDrawingMode = false;
      canvasRef.current.discardActiveObject();
      canvasRef.current.getObjects().forEach(obj => {
        obj.set({ selectable: false });
      });
    }

    canvasRef.current.renderAll();
  }, [tool]);

  const genPresets = (presets = presetPalettes) => {
    return Object.entries(presets).map(([label, colors]) => ({
      label,
      colors,
      key: label
    }));
  };

  // Add color presets
  const presets = genPresets({
    red: generate("#F5222D"), // Red
    green: generate("#52C41A"), // Green
    blue: generate("#1677FF"), // Blue
    yellow: generate("#FADB14"), // Yellow
    grey: generate("#818181") // Grey
  });

  return (
    <MyModal
      title={id ? t("bodyChart.edit") : t("bodyChart.add")}
      open={showModal}
      width={modalSize}
      onCancel={() => {
        onCancel();
        setIsColorPopupOpen(false);
      }}
      okText={t("save")}
      onOk={submit}
    >
      <div
      // style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        {/* Button Toolbar */}
        <div
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
            zIndex: 1000,
            paddingBottom: "8px"
            // borderBottom: "1px solid #ddd"
          }}
        >
          <Space style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
            <Space.Compact block size="large">
              <Button
                onClick={() => setTool("SELECT")}
                type={tool === "SELECT" ? "primary" : "dashed"}
                title={t("bodyChart.tools.select")}
              >
                <FontAwesomeIcon icon={faHandPointer} />
              </Button>
            </Space.Compact>
            <Space.Compact block size="large">
              <Button
                onClick={() => enableFreeDrawing()}
                type={tool === "FREE_DRAW" ? "primary" : "dashed"}
                title={t("bodyChart.tools.draw")}
              >
                <FontAwesomeIcon icon={faPencil} />
              </Button>

              <Button
                onClick={() => setTool("CIRCLE")}
                type={tool === "CIRCLE" ? "primary" : "dashed"}
                title={t("bodyChart.tools.circle")}
              >
                <FontAwesomeIcon icon={faCircle} />
              </Button>
              <Button
                onClick={() => setTool("RECT")}
                type={tool === "RECT" ? "primary" : "dashed"}
                title={t("bodyChart.tools.rectangle")}
              >
                <FontAwesomeIcon icon={faSquare} />
              </Button>
              <Button
                onClick={() => setTool("LINE")}
                type={tool === "LINE" ? "primary" : "dashed"}
                title={t("bodyChart.tools.line")}
              >
                /
              </Button>
              <Button
                onClick={() => setTool("ARROW")}
                type={tool === "ARROW" ? "primary" : "dashed"}
                title={t("bodyChart.tools.arrow")}
              >
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  style={{ transform: "rotate(-45deg)" }}
                />
              </Button>
              <Button
                onClick={() => setTool("TEXT")}
                type={tool === "TEXT" ? "primary" : "dashed"}
                title={t("bodyChart.tools.text")}
              >
                <FontAwesomeIcon icon={faFont} />
              </Button>
            </Space.Compact>
            <Space.Compact block size="large">
              {/* <Popover
                hidden
                content={content}
                trigger="click"
                open={popoverOpen}
                onOpenChange={setPopoverOpen}
              >
                <Button style={{ backgroundColor: color, color: "#fff" }}>
                  Color
                </Button>
              </Popover> */}
              <ColorPicker
                value={color}
                presets={presets}
                // onOpenChange={setIsColorPopupOpen}
                // open={isColorPopupOpen}
                onChange={colorObj => {
                  const newColor = colorObj.toHexString(); // Convert to HEX string
                  setColor(newColor); // Update state
                }}
              />
            </Space.Compact>
            <Space.Compact block size="large">
              {tool !== "TEXT" && (
                <Select
                  defaultValue={strokeWidth} // Default value of stroke width
                  onChange={value => setStrokeWidth(value)} // Handle changes
                  style={{ width: 90 }} // Set a width for the dropdown
                  disabled={isArrowSelected}
                  value={strokeWidth} // Dynamically update selected value
                  dropdownRender={menu => <div>{menu}</div>}
                >
                  {[
                    { value: 2, label: "2px" },
                    { value: 4, label: "4px" },
                    { value: 6, label: "6px" },
                    { value: 8, label: "8px" },
                    { value: 10, label: "10px" },
                    { value: 14, label: "14px" },
                    { value: 18, label: "18px" }
                  ].map(({ value }) => (
                    <Select.Option key={value} value={value}>
                      <div
                        style={{
                          width: "100%", // Full width of the dropdown
                          height: `${value}px`, // A fixed height for consistent spacing
                          borderBottom: `${value}px solid ${color}` // Line thickness matches stroke width
                        }}
                      ></div>
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Space.Compact>

            <Space.Compact block size="large">
              <Select
                placeholder={t("bodyChart.image.select")}
                value={selectedImage}
                onChange={value => {
                  setSelectedImage(value);
                  loadSelectedImage(value);
                }}
                style={{ width: 140 }}
              >
                {imageOptions.map((url, index) => (
                  <Select.Option key={index} value={url}>
                    {t(`bodyChart.image.image${index + 1}`)}
                  </Select.Option>
                ))}
              </Select>
            </Space.Compact>

            {isMobile && <br />}
            <Space.Compact block size="large">
              <Button
                onClick={() => setTool("PAN")}
                type={tool === "PAN" ? "primary" : "dashed"}
              >
                <FontAwesomeIcon icon={faUpDownLeftRight} />
              </Button>
              <Button onClick={() => handleZoom(0.1)} title="Zoom In">
                <FontAwesomeIcon icon={faSearchPlus} />
              </Button>
              <Button
                disabled={zoomLevel === 1}
                onClick={() => handleZoom(-0.1)}
                title="Zoom Out"
              >
                <FontAwesomeIcon icon={faSearchMinus} />
              </Button>
              {isCanvasTransformed && (
                <Button onClick={() => resetPanning()}>{t("reset")}</Button>
              )}
            </Space.Compact>
            {selectionCount > 0 && (
              <Space.Compact block size="large">
                <Button
                  onClick={deleteSelectedObject}
                  color="red"
                  variant="filled"
                  hidden={selectionCount === 0}
                >
                  {t("delete")}
                </Button>
              </Space.Compact>
            )}
          </Space>
        </div>

        {/* Scrollable Canvas Container */}
        <div
          style={{
            flex: 1,
            overflow: "auto",
            padding: "0px",
            backgroundColor: "#f9f9f9"
          }}
        >
          <canvas id="fabric-canvas" style={{ overflow: "hidden" }} />
        </div>
      </div>
    </MyModal>
  );
};

export default BodyChartUpsertModal;
