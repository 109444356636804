import { useEffect, useState } from "react";
import DebounceSelect from "./DebounceSelect";
import { Space } from "antd";
import { Button } from "antd-mobile";
import { post } from "../api/Request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UpsertPatientModal from "../pages/patients/UpsertPatientModal";
import { useAuth } from "../context/AuthContext";
import { ImageType } from "../types/Plan";
import { PatientUpsert } from "../types/Patient";
import { useTranslation } from "react-i18next";
import { config } from "../data/Constants";

// Usage of DebounceSelect
interface UserValue {
  label: string;
  value: string;
}

interface Props {
  value?: number | null | number[];
  label?: string | undefined;
  isMulti?: boolean;
  onChange: (value: number | number[] | undefined) => void;
}

const PatientSelect3: React.FC<Props> = ({
  value,
  label,
  isMulti,
  onChange
}) => {
  const [sValue, setSValue] = useState<any>();
  const { user } = useAuth();
  const { t } = useTranslation();

  const initialNewPatientData = {
    id: 0,
    first_name: "",
    last_name: "",
    send_login_details: true,
    patient_consent: true,
    phone: "",
    dial_code: user?.settings.default_phone_dial_code,
    image_type: ImageType.photo
  };
  const [patientData, setPatientData] = useState<PatientUpsert>(
    initialNewPatientData
  );
  const [showPatientModal, setShowPatientModal] = useState(false);

  async function fetchUserList(
    search: string,
    page: number
  ): Promise<UserValue[]> {
    return fetch(
      `${config.API_URL}patient/all?search=${search}&page=${page}&allClinics=1&pageSize=25`,
      {
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(body => {
        return body.patients.map(
          (user: {
            first_name: string;
            last_name: string;
            id: number;
            login: { username: string };
          }) => ({
            label: `${user.first_name} ${user.last_name}`,
            value: user.id.toString()
          })
        );
      });
  }

  useEffect(() => {
    // console.log("value", value, sValue);
    if (value && sValue === undefined) {
      post("patient/getPatientNamesV2/", { ids: value })
        .then(p => {
          // setPatientName(r.data);
          // console.log(p.data);
          setSValue(p.data);
        })
        .catch(e => {});
    } else if (value === undefined) setSValue(undefined);
  }, [value]);

  const handleSelectChange = (selectedOptions: any) => {
    setSValue(selectedOptions);
    if (isMulti) {
      // In case of multi-select, map the selected options to an array of numbers
      const selectedValues = Array.isArray(selectedOptions)
        ? selectedOptions.map(option => parseInt(option.value))
        : [];
      onChange(selectedValues);
    } else {
      // In case of single select, directly parse the selected option's value to a number
      const selectedValue = selectedOptions
        ? parseInt(selectedOptions.value)
        : undefined;
      onChange(selectedValue);
    }
  };

  return (
    <>
      <Space.Compact block>
        <DebounceSelect
          mode={isMulti ? "multiple" : undefined}
          placeholder="Search patients"
          value={sValue}
          size="large"
          showSearch
          fetchOptions={fetchUserList}
          onChange={handleSelectChange}
          style={{ width: "100%" }}
        />
        <Button
          onClick={_ => {
            setPatientData(initialNewPatientData);
            setShowPatientModal(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} title={t("patient.addNewPatient")} />{" "}
        </Button>
      </Space.Compact>
      {user && user.settings && (
        <UpsertPatientModal
          initialData={patientData}
          showModal={showPatientModal}
          onDone={(id, name) => {
            // onChange({ label: name, value: id });
            if (isMulti) {
              //
            } else {
              setSValue({ label: name, value: id });
              onChange(id);
            }
            setShowPatientModal(false);
          }}
          onCancel={() => {
            setShowPatientModal(false);
          }}
        />
      )}
    </>
  );
};

export default PatientSelect3;
