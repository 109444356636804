import React, { useEffect, useState } from "react";
import { get } from "../../api/Request";
import { TemplateList } from "../../types/Patient";
import {
  Table,
  Button,
  Typography,
  Input,
  Select,
  Tabs as Tabs2,
  Result,
  Segmented,
  Space
} from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import {
  RmpTemplateCat,
  TemplateTableDTO,
  TemplateTableProps,
  UserTemplateCat
} from "../../types/Plan";
import { formatDate } from "../../helpers/dateHelper";
import { useGlobalContext } from "../../context/GlobalContext";
import { Link, useNavigate } from "react-router-dom";
import { FilterValue } from "antd/lib/table/interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faPlus,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash/debounce";
import { Divider, DotLoading, List, SwipeAction } from "antd-mobile";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  faFileLines,
  faRectangleList
} from "@fortawesome/free-regular-svg-icons";
import Pract from "../../components/helper/Pract";
// import { Tabs } from "antd-mobile";

const { Option } = Select;

interface Props {
  initialData: TemplateTableProps;
  userCats: UserTemplateCat[];
  rmpCats: RmpTemplateCat[];
  isInModal: boolean;
  onSelectTemplate: (id: number) => void;
}
const TemplatesTable: React.FC<Props> = ({
  initialData,
  userCats,
  rmpCats,
  isInModal,
  onSelectTemplate
}) => {
  // console.log("load component templates TABLE");
  const { Search } = Input;
  const { user } = useAuth();
  const { Text } = Typography;
  const { language, isTabletOrMobile, isMobile } = useGlobalContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [templates, setTemplates] = useState<TemplateList[]>();
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ["bottomCenter"]
  });

  const [loading, setLoading] = useState(true);
  const [tableProps, setTableProps] = useState<TemplateTableProps>(initialData);
  const [useInfiniteScroll, setUseInfiniteScroll] = useState(false);

  const [noData, setNoData] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (templates) {
      if (tableProps.templateType === "user") {
        if (
          tableProps &&
          (tableProps.search.length > 0 || tableProps.catId > 0)
        )
          return setNoData(false);
        if (loading) return setNoData(false);

        if (loading === false && templates.length > 0) setNoData(false);
        else setNoData(true);
      } else {
        setNoData(false);
      }
    }
  }, [loading, templates, tableProps]);

  const getTabelData = () => {
    setLoading(true);

    // console.log("getting templates");
    get<TemplateTableDTO>("template/loadTemplates", tableProps)
      .then(r => {
        if (
          templates !== undefined &&
          tableProps &&
          tableProps.page > 1 &&
          useInfiniteScroll === true
        )
          setTemplates(templates.concat(r.data.data));
        else setTemplates(r.data.data);

        // setPatients(r.data.patients);
        setPagination({
          ...pagination,
          total: r.data.total_items_filtered,
          current: tableProps?.page
        });
        setHasMore(r.data.current_page <= r.data.total_pages);

        // setTemplates(r.data.data);

        // setPagination({
        //   ...pagination,
        //   total: r.data.total_items_filtered,
        //   current: tableProps?.page
        // });
      })
      .catch(_ => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (tableProps !== undefined) getTabelData();
  }, [tableProps, user]);

  useEffect(() => {
    if (isTabletOrMobile) {
      if (!isInModal) {
        setUseInfiniteScroll(true);
      } else {
        setUseInfiniteScroll(false);
      }
    } else {
      setUseInfiniteScroll(false);
    }
  }, [isTabletOrMobile, isInModal]);

  useEffect(() => {
    // console.log("pushing history templates", tableProps);
    if (!isInModal)
      navigate({
        pathname: window.location.pathname,
        search:
          "?" +
          Object.keys(tableProps)
            .map(key => key + "=" + tableProps[key])
            .join("&")
      });
  }, [tableProps, isInModal]);

  const openTemplate = (id: number) => {
    navigate("/template/" + id);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter
  ) => {
    // console.log("filters", filters);
    if (tableProps !== undefined) {
      setPagination(pagination);
      setTableProps({
        ...tableProps,
        page: pagination.current ?? tableProps.page,
        pageSize: pagination.pageSize ?? tableProps.pageSize,
        sort: sorter.columnKey,
        sortDirection: sorter.order
      });
    }
  };

  const columns: ColumnsType<TemplateList> = user?.clinics
    ? [
        {
          title: t("created"),
          dataIndex: "created",
          key: "created",
          sorter: true,
          defaultSortOrder: "descend",
          className: "pointer",
          render: date => (
            <Text type="secondary">{formatDate(date, language)}</Text>
          ),
          responsive: ["md"]
        },
        {
          title: t("name"),
          key: "name",
          sorter: true,
          className: "pointer",
          render: (_, record) => (
            <span>
              <Text className="tmain_col">
                {record.name.length > 0 ? record.name : "--"}
              </Text>
              {record.desc !== null && record.desc.length > 0 ? (
                <div>
                  <Text type="secondary" className="one-line">
                    {record.desc}
                  </Text>
                </div>
              ) : (
                <>
                  <br />
                  <Text type="secondary">{t("template.noDesc")}</Text>
                </>
              )}
            </span>
          )
        },
        {
          title: t("category"),
          key: "rmpcats",
          className:
            tableProps && tableProps.templateType === "user"
              ? "hidden"
              : "pointer",
          render: (_, record) => (
            <Text className="secondary">
              {record.template_cats &&
                record.template_cats.map(catId => (
                  <span key={catId.toString()}>
                    {rmpCats.find(rmpCat => rmpCat.id === catId)?.name}{" "}
                  </span>
                ))}
            </Text>
          ),
          responsive: ["md"]
        },
        {
          title: t("category"),
          key: "usercats",
          className:
            tableProps && tableProps.templateType !== "user"
              ? "hidden"
              : "pointer",
          render: (_, record) => (
            <Text className="secondary">
              {userCats.find(uc => uc.id === record.template_category_id)?.name}
            </Text>
          ),
          responsive: ["md"]
        },

        {
          title: t("practitioner"),
          className:
            tableProps && tableProps.templateType !== "user"
              ? "hidden"
              : "pointer",
          key: "practitioner",
          render: (_, record) => <Pract id={record.practitioner_id} />
        },

        {
          key: "actions",
          render: (d, record) => (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {" "}
              {isInModal ? (
                <Button
                  size="middle"
                  onClick={() => onSelectTemplate(record.id)}
                >
                  {t("template.loadTemplate")}
                </Button>
              ) : (
                <Space.Compact style={{ float: "right" }}>
                  <Button
                    size="middle"
                    variant="solid"
                    color="primary"
                    onClick={() => openTemplate(record.id)}
                  >
                    {t("view")}
                  </Button>
                  {tableProps.templateType === "user" &&
                    (record.practitioner_id === user.practitioner.id ||
                      record.practitioner_id === 0) && (
                      <Button
                        size="middle"
                        variant="filled"
                        color="primary"
                        onClick={_ => {
                          navigate(
                            "/exercise-plan?id=" + record.id + "&mode=template"
                          );
                        }}
                      >
                        {t("edit")}
                      </Button>
                    )}
                </Space.Compact>
              )}
            </div>
          ),
          responsive: ["md"]
        }
      ]
    : [];

  return (
    <>
      {user && tableProps && (
        <>
          {isMobile || (isInModal !== undefined && isInModal === true) ? (
            <>
              <Segmented
                options={[
                  {
                    label: t("template.user"),
                    value: "user"
                  },
                  {
                    label: t("template.rmp"),
                    value: "rmp"
                  },
                  {
                    label: t("template.community"),
                    value: "community"
                  }
                ]}
                onChange={t =>
                  setTableProps({
                    ...tableProps,
                    templateType: t.toString(),
                    catId: 0,
                    page: 1
                  })
                }
                block
                defaultValue={tableProps.templateType}
                size="large"
              />
            </>
          ) : (
            <Tabs2
              centered={true}
              style={{ margin: "0 auto" }}
              onChange={t =>
                setTableProps({
                  ...tableProps,
                  templateType: t,
                  catId: 0,
                  page: 1
                })
              }
              className="patient-profile-tabs"
              items={[
                { label: t("template.user"), key: "user" },
                { label: t("template.rmp"), key: "rmp" },
                { label: t("template.community"), key: "community" }
              ]}
              defaultActiveKey={tableProps.templateType}
            />
          )}
          <div className="box">
            <Space.Compact
              block
              size="large"
              direction={isMobile ? "vertical" : "horizontal"}
              style={{ marginBottom: 8 }}
              hidden={noData && tableProps.practitionerId === 0}
            >
              <Search
                key="search"
                style={{ width: "100% " }}
                placeholder={t("search")}
                allowClear
                onChange={debounce(e => {
                  setTableProps({
                    ...tableProps,
                    search: e.target.value,
                    page: 1
                  });
                }, 300)}
                enterButton={<FontAwesomeIcon icon={faSearch} />}
                loading={loading && tableProps.search.length > 0}
                defaultValue={tableProps.search}
              />
              {tableProps.templateType !== "user" && (
                <Select
                  key="all_c2"
                  style={{ width: "100% " }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={true}
                  value={tableProps.catId}
                  onChange={e =>
                    setTableProps({ ...tableProps, catId: e, page: 1 })
                  }
                >
                  <Option value={0}>{t("template.allCats")}</Option>

                  {rmpCats.map(uc => (
                    <Option value={uc.id} key={uc.id.toString()}>
                      {uc.name}
                    </Option>
                  ))}
                </Select>
              )}
              {tableProps.templateType === "user" &&
                user.enable_template_categories === true && (
                  <Select
                    key="all_c"
                    style={{ width: "100% " }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={true}
                    value={tableProps.catId}
                    onChange={e =>
                      setTableProps({ ...tableProps, catId: e, page: 1 })
                    }
                  >
                    <Option value={0}>{t("template.allCats")}</Option>
                    {userCats.map(uc => (
                      <Option key={uc.id.toString()} value={uc.id}>
                        {uc.name}
                      </Option>
                    ))}
                  </Select>
                )}
              {tableProps.templateType === "user" && (
                <Select
                  key="pract"
                  allowClear
                  value={tableProps.practitionerId}
                  showSearch={Object.entries(user.practitioners).length > 15}
                  optionFilterProp="children"
                  filterOption={true}
                  autoClearSearchValue={false}
                  style={{ width: "100% " }}
                  placeholder={t("pract.selectPract")}
                  onChange={e => {
                    // setPagination({ ...pagination, current: 1 });
                    setTableProps({
                      ...tableProps,
                      practitionerId: e,
                      page: 1
                    });
                  }}
                  onClear={() => {
                    // e.preventDefault();
                    setTableProps({
                      ...tableProps,
                      practitionerId: 0,
                      page: 1
                    });
                  }}
                >
                  <Option key="22" value={0}>
                    {t("allPractitioners")}
                  </Option>
                  {Object.entries(user.practitioners)
                    .sort(([k1, a], [k2, b]) =>
                      a.first_name.localeCompare(b.first_name)
                    )
                    .map(([key, p]) => (
                      <Option key={p.id.toString()} value={p.id}>
                        {p.first_name + " " + p.last_name}
                      </Option>
                    ))}
                </Select>
              )}
            </Space.Compact>

            {!noData && !useInfiniteScroll && (
              <Table
                // key="created"
                locale={{
                  emptyText: tableProps.search.length > 0 && (
                    <Result
                      icon={
                        <FontAwesomeIcon icon={faRectangleList} size="4x" />
                      }
                      title={t("template.nothingFound")}
                      subTitle={t("template.nothingFound2")}
                      extra={
                        <Button type="primary" className="green-btn">
                          <Link to="/exercise-plan?id=0&mode=template">
                            <FontAwesomeIcon icon={faPlus} />

                            {t("template.createNew")}
                          </Link>
                        </Button>
                      }
                    />
                  )
                }}
                // showHeader={templates.length ? true : false}
                showHeader={!noData}
                columns={columns}
                dataSource={templates}
                rowKey="created"
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                style={{ width: "100%" }}
                onRow={record => {
                  return {
                    onClick: event => {
                      if (isInModal) onSelectTemplate(record.id);
                      else openTemplate(record.id);
                    },
                    onDoubleClick: event => {}, // double click row
                    onContextMenu: event => {}, // right button click row
                    onMouseEnter: event => {}, // mouse enter row
                    onMouseLeave: event => {} // mouse leave row
                  };
                }}
              />
            )}

            {useInfiniteScroll && !noData && (
              <List>
                <InfiniteScroll
                  dataLength={templates ? templates.length : 0}
                  next={() => {
                    // console.log("loading next", templates);
                    setTableProps({
                      ...tableProps,
                      page: tableProps.page + 1
                    });
                  }}
                  hasMore={hasMore}
                  loader={<DotLoading />}
                  endMessage={<Divider />}
                >
                  {templates &&
                    templates.map(template => (
                      <SwipeAction
                        // ref={ref}
                        key={template.id}
                        closeOnAction={true}
                        closeOnTouchOutside={false}
                      >
                        <List.Item
                          className="one-line-desc"
                          key={template.id}
                          clickable
                          onClick={() => {
                            if (isInModal) onSelectTemplate(template.id);
                            else openTemplate(template.id);
                          }}
                          prefix={
                            <FontAwesomeIcon
                              icon={faFileLines}
                              className="prefix-icon"
                            />
                          }
                          arrow={
                            <>
                              <FontAwesomeIcon icon={faChevronRight} />
                            </>
                          }
                          description={
                            <>
                              {template.desc !== null &&
                              template.desc.length ? (
                                <>
                                  {template.desc}
                                  <br />
                                </>
                              ) : (
                                ""
                              )}

                              <Text type="secondary">
                                {formatDate(template.created, language)}
                              </Text>
                            </>
                          }
                        >
                          {template.name.length > 0 ? (
                            template.name
                          ) : (
                            <Text type="secondary">{t("plan.noName")}</Text>
                          )}
                        </List.Item>
                      </SwipeAction>
                    ))}
                </InfiniteScroll>
              </List>
            )}
            {noData && (
              <Result
                icon={<FontAwesomeIcon icon={faRectangleList} size="4x" />}
                title={t("template.noTemplates")}
                subTitle={t("template.noTemplates2")}
                extra={
                  <Button type="primary" className="green-btn">
                    <Link to="/exercise-plan?id=0&mode=template">
                      <FontAwesomeIcon icon={faPlus} />

                      {t("template.createFirst")}
                    </Link>
                  </Button>
                }
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

function areEqual(prevProps, nextProps) {
  // console.log("prev, next preview", prevProps, nextProps);

  return true;
  // return (
  //   prevProps.key === nextProps.key &&
  //   prevProps.imageType === nextProps.imageType &&
  //   prevProps.added === nextProps.added
  // );
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

const TemplatesMemo = React.memo(TemplatesTable, areEqual);
export default TemplatesTable;
// export default React.memo(Templates, areEqual);
// export default Templates;
