import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import { Column } from "@ant-design/plots";
import { get } from "../../api/Request";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Select, Skeleton, Space } from "antd";
import ValueLabel from "../../components/layout/ValueLabel";

const { Option } = Select;
interface Props {
  practitionerId: number;
  onPractitionerChange?: (practId: number) => void;
}

const PractitionerPlanChart: React.FC<Props> = ({ practitionerId }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const chartRef = useRef<any>(null);

  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState("30");
  const [previous, setPrevious] = useState(0);

  const [data, setData] = useState<any>();
  const interpolateColor = (startColor, endColor, factor) => {
    const result = startColor.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (endColor[i] - result[i]));
    }
    return `rgb(${result.join(",")})`;
  };

  const getColor = y => {
    const startColor = [128, 128, 128]; // Darker grey
    const endColor = [173, 255, 47]; // Bright green
    const maxY = 20; // Maximum number of plans for full bright green
    const factor = Math.min(y, maxY) / maxY;
    return interpolateColor(startColor, endColor, factor);
  };

  const getData = () => {
    setLoading(true);
    get("practitioner/plansCreatedChartData/" + practitionerId, {
      period,
      previous
    })
      .then(r => {
        setData({
          data: r.data,
          xField: "x",
          yField: "y",
          colorField: "x",
          color: ({ x }) => {
            const record = r.data.find((item: any) => item.x === x);
            const y = record ? record.y : 0;
            return getColor(y);
          },
          xAxis: {
            label: {
              autoHide: true
            }
          },
          allowDecimals: false,

          yAxis: {
            tickInterval: 1,
            minInterval: 1,
            min: 0
          },
          // yAxis: {
          //   minLimit: 0, // Start the y-axis at 0

          //   nice: true // Ensure the y-axis ticks are whole numbers
          //   // tickInterval: 1 // Ensure the y-axis ticks are whole numbers
          // },
          tooltip: {
            formatter: data => {
              return {
                name: "Plans created",
                value: `${data.y}`
              };
            },
            fields: ["y"] // Specify which fields to show in the tooltip and their format
          },
          label: {
            text: d => `${(d.y * 100).toFixed(1)}%`,
            textBaseline: "bottom"
          }
        });
      })
      .catch(e => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (practitionerId === 0) {
      setPeriod("30");
    }
    getData();
  }, [practitionerId, period, previous]);

  if (data === undefined) {
    return <Skeleton active />;
  }
  const fromDate = data.data[0].x;
  const toDate = data.data[data.data.length - 1].x;
  const totalPlans = data.data.reduce((total, item) => total + item.y, 0);
  const plansPerDay = (totalPlans / data.data.length).toFixed(1);

  return (
    <div style={{}}>
      {user && data !== undefined ? (
        <>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}>
              <ValueLabel label="From date:">{fromDate}</ValueLabel>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <ValueLabel label="To date:">{toDate}</ValueLabel>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <ValueLabel label="Plans prescribed:">{totalPlans}</ValueLabel>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <ValueLabel label="Plans per day">
                {plansPerDay == "0.0" ? "--" : plansPerDay}
              </ValueLabel>
            </Col>
          </Row>
          <div style={{ position: "relative", height: "400px" }}>
            {" "}
            <Column
              loading={loading}
              {...data}
              onReady={plot => (chartRef.current = plot)}
            />
          </div>

          {user && (
            <Space.Compact
              size="large"
              block
              // direction={isMobile ? "vertical" : "horizontal"}
              style={{ marginBottom: 0, marginTop: 16 }}
              // hidden={noData}
            >
              <Button
                block
                key="prev"
                onClick={_ => {
                  setPrevious(previous => previous + 1);
                }}
              >
                {t("previous")}
              </Button>
              <Select
                // value={period}
                disabled={practitionerId === 0}
                defaultValue={period}
                onChange={e => {
                  setPeriod(e);
                  setPrevious(0);
                }}
              >
                <Option value="30">30 {t("days")}</Option>
                <Option value="60">60 {t("days")}</Option>
                <Option value="90">90 {t("days")}</Option>
                <Option value="365">1 {t("year")}</Option>
              </Select>
              <Button
                block
                key="next"
                disabled={previous === 0}
                onClick={_ => setPrevious(previous => previous - 1)}
              >
                {t("next")}
              </Button>
            </Space.Compact>
          )}
        </>
      ) : (
        <Skeleton active />
      )}
    </div>
  );
};

export default PractitionerPlanChart;
