import React, { useEffect, useRef } from "react";
import { fabric } from "fabric";

const BodyChartThumbnail = ({ json, width = 160, height = 130 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!json) return;

    const canvas = new fabric.StaticCanvas(canvasRef.current);

    try {
      const data = JSON.parse(json);

      if (data.backgroundImage) {
        fabric.Image.fromURL(data.backgroundImage.src, img => {
          // Calculate scale to fit the thumbnail size while maintaining aspect ratio
          const scale = Math.min(width / img.width, height / img.height);

          canvas.setWidth(width);
          canvas.setHeight(height);

          // Scale and center the objects
          canvas.setZoom(scale);
          canvas.setViewportTransform([scale, 0, 0, scale, 0, 0]);

          // Load the JSON data
          canvas.loadFromJSON(json, () => {
            canvas.renderAll();
          });
        });
      }
    } catch (error) {
      console.error("Error rendering thumbnail:", error);
    }
  }, [json, width, height]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        // border: "1px solid #ddd",
        // borderRadius: "4px",
        backgroundColor: "#fff"
      }}
    />
  );
};

export default BodyChartThumbnail;
