import React from "react";
import { Button, Card, Space } from "antd";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { ClinicalNoteTemplate } from "../../types/Patient";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import ConfirmButton from "../../components/helper/ConfirmButton";

interface TemplateListProps {
  templates: ClinicalNoteTemplate[];
  title: string;
  droppableId: string;
  onEdit: (template: ClinicalNoteTemplate) => void;
  onDelete: (id: number) => void;
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "#fefefe" : "#fafafa",
  borderRadius: 8,
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#eee" : "#fff",
  padding: grid,
  width: "100%", // Adjust for responsive design
  margin: "10px 0"
});

const TemplateList: React.FC<TemplateListProps> = ({
  templates,
  title,
  droppableId,
  onEdit,
  onDelete
}) => (
  <Droppable droppableId={droppableId}>
    {(provided, snapshot) => (
      <Card
        ref={provided.innerRef}
        style={getListStyle(snapshot.isDraggingOver)}
        title={title}
      >
        {templates.map((item, index) => (
          <Draggable
            key={item.id.toString()}
            draggableId={item.id.toString()}
            index={index}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <FontAwesomeIcon
                    icon={faGripVertical}
                    style={{ marginRight: 12, cursor: "grab" }}
                    {...provided.dragHandleProps} // Ensure drag icon is draggable
                  />
                  <div>
                    <strong>{item.name}</strong>
                  </div>
                  <div>
                    <Space.Compact size="middle" block>
                      <Button
                        onClick={() => onEdit(item)}
                        icon={<FontAwesomeIcon icon={faEdit} />}
                        color="primary"
                        variant="filled"
                      >
                        {t("edit")}
                      </Button>
                      <ConfirmButton
                        btnText={
                          <>
                            <FontAwesomeIcon icon={faTrashAlt} /> {t("delete")}
                          </>
                        }
                        message={t("confirmations.delete")}
                        btnProps={{
                          block: true,
                          color: "red",
                          variant: "filled"
                        }}
                        onConfirm={() => {
                          onDelete(item.id);
                        }}
                      />
                    </Space.Compact>
                  </div>
                </div>
              </div>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </Card>
    )}
  </Droppable>
);

export default TemplateList;
