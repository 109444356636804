import {
  Form,
  Button,
  Space,
  Dropdown,
  Result,
  List,
  Skeleton,
  Row,
  Col,
  Card
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { get, post } from "../../api/Request";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

import MyModal from "../../components/MyModal";
import ReactQuill from "react-quill"; // Import ReactQuill
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor
import { fabric } from "fabric"; // Import fabric.js

import { ClinicalNoteTemplate } from "../../types/Patient";
import { faEdit, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChildReaching,
  faDownload,
  faPrint,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { config } from "../../data/Constants";
import { formatDate, formatTime } from "../../helpers/dateHelper";
import CustomNotesEmailModal from "./CustomNotesEmailModal";
import BodyChartUpsertModal from "./BodyChartUpsertModal";
import { useGlobalContext } from "../../context/GlobalContext";
import BodyChartThumbnail from "../../components/BodyChartThumbnail";
import { uploadBodyCharts } from "./BodyChartUtils";

interface Props {
  id: number; // Note ID, if > 0 it's editing, otherwise it's adding a new note
  patientId: number; // The ID of the patient related to the note
  patientName: string; // The name of the patient
  showModal: boolean;
  onDone: () => void;
  onCancel: () => void;
}

interface BodyChart {
  id: number;
  json: string;
  clinical_note_id: number;
}

const NoteUpsertModal: React.FC<Props> = ({
  id,
  patientId,
  patientName,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { isTabletOrMobile } = useGlobalContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [localId, setLocalId] = useState(0); // ID of the note
  const [contentChanged, setContentChanged] = useState(false);
  const [showCustomEmailModal, setShowCustomEmailModal] = useState(false);
  const [showBodyChartModal, setShowBodyChartModal] = useState(false);
  const [bodyCharts, setBodyCharts] = useState<BodyChart[]>([]);
  const [bodyChartId, setBodyChartId] = useState(0);
  const [printLoading, setPrintLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const [templates, setTemplates] = useState<ClinicalNoteTemplate[]>([]);
  const quillRef = useRef<ReactQuill | null>(null); // Reference to ReactQuill
  const [quillRange, setQuillRange] = useState<any>(null); // Store the cursor position

  const canvasToImageData = json => {
    return new Promise((resolve, reject) => {
      try {
        const data = JSON.parse(json);
        // const data = json;

        console.log(data, "dat");
        // Create a Fabric.js canvas instance
        const canvas = new fabric.StaticCanvas(null);

        if (data.backgroundImage) {
          // Use backgroundImage to set canvas dimensions
          fabric.Image.fromURL(data.backgroundImage.src, img => {
            canvas.setWidth(img.width);
            canvas.setHeight(img.height);

            // Load the Fabric.js JSON data into the canvas
            canvas.loadFromJSON(json, () => {
              // Convert the canvas to a Base64 image
              const imageData = canvas.toDataURL({
                format: "png",
                quality: 0.3
              });
              resolve(imageData);
            });
          });
        } else {
          reject("No background image found in the JSON");
        }
      } catch (error) {
        reject(`Error parsing JSON or processing canvas`);
      }
    });
  };

  const navigate = useNavigate();
  const getTemplates = () => {
    setLoading(true);
    get<ClinicalNoteTemplate[]>("note/getTemplates/")
      .then(r => {
        setTemplates(r.data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!showModal) return;

    setLocalId(id);
    setBodyCharts([]);

    if (templates.length === 0) {
      getTemplates();
    }
    if (id > 0) {
      getData(id);
    } else {
      // If adding a new note, reset form
      form.resetFields();
      setContentChanged(false);
    }
  }, [id, showModal]);

  const getData = (id: number) => {
    setDataLoading(true);
    get(`/note/note/${id}`)
      .then(response => {
        form.resetFields();
        form.setFieldsValue({
          note: response.data.note
        });
        setBodyCharts(response.data.body_charts);
      })
      .catch(() => {})
      .finally(() => {
        setContentChanged(false);
        setDataLoading(false);
      });
  };

  const submit = () => {
    form
      .validateFields()
      .then(async v => {
        setLoading(true);

        // Convert all body charts to image data

        const data = {
          note: v.note,
          patient_id: patientId,
          practitioner_id: user?.practitioner?.id,
          body_charts: bodyCharts
        };

        const apiCall =
          localId > 0
            ? post(`/note/edit/${localId}`, data)
            : post(`/note/add`, data);

        apiCall
          .then(r => {
            setLocalId(r.data.id);
            setContentChanged(false);
            onDone();
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const insertHTML = (html: string) => {
    // Replace [!PATIENT_NAME!] with the patient's name
    html = html.replace("[!PATIENT_NAME!]", patientName);

    if (user) {
      html = html.replace(
        "[!DATE!]",
        formatDate("", user.practitioner["lang"])
      );
      html = html.replace("[!TIME!]", formatTime(""));
      html = html.replace(
        "[!PRACTITIONER_NAME!]",
        user.practitioner["first_name"] + " " + user.practitioner["last_name"]
      );
    }

    const editor = quillRef.current?.getEditor(); // Access the Quill editor instance

    if (editor) {
      // Check if the editor content is empty (only contains <p><br></p>)
      if (quillRange) {
        const { index, length } = quillRange; // Get the selection range
        if (length > 0) {
          // If there's a selection, remove the selected text
          editor.deleteText(index, length);
        }
      }

      if (editor.getText().trim() === "") {
        // If the editor is empty, insert the HTML at the beginning
        editor.clipboard.dangerouslyPasteHTML(0, html);
      } else {
        if (quillRange && quillRange.index !== null) {
          // If the cursor position is stored, insert HTML at that position
          editor.clipboard.dangerouslyPasteHTML(quillRange.index, html);
        } else {
          editor.clipboard.dangerouslyPasteHTML(
            editor.getLength(),
            "<br />" + html
          );
        }
      }
    }
  };

  const handleMenuClick = ({ key }: { key: string }) => {
    const template = templates.find(t => t.id.toString() === key);
    if (template) {
      insertHTML(template.template);
    }
  };

  const createMenuItems = (category: string) =>
    templates
      .filter(template => template.category === category)
      .map(template => ({
        label: template.name,
        key: template.id.toString()
      }));

  const rfMenuProps = {
    items: createMenuItems("rf"),
    onClick: handleMenuClick
  };

  const templateMenuProps = {
    items: createMenuItems("template"),
    onClick: handleMenuClick
  };

  const printDownload = action => {
    const data = {
      id: localId.toString(),
      action: action
    };
    setPrintLoading(true);
    uploadBodyCharts(localId, bodyCharts)
      .then(() => {
        window.open(
          config.API_URL +
            "note/printDownload?" +
            new URLSearchParams(data).toString(),
          "_blank"
        );
      })
      .finally(() => {
        setPrintLoading(false);
      });
  };

  return (
    <MyModal
      title={localId > 0 ? t("cn.edit") : t("cn.add")}
      open={showModal}
      width={1000}
      onCancel={onCancel}
      okText={t("save")}
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelText={contentChanged ? t("cancel") : t("close")}
      onOk={() => {
        form
          .validateFields()
          .then(v => {
            console.log(v, "v");
            submit();
          })
          .catch(() => {});
      }}
    >
      <Space.Compact block>
        <Button
          block
          key="save"
          type="primary"
          onClick={() => submit()}
          loading={loading}
        >
          {t("save")} {contentChanged && "*"}
        </Button>
        <Button
          disabled={localId === 0}
          block
          key="0"
          type="primary"
          loading={emailLoading}
          onClick={() => {
            setEmailLoading(true);
            uploadBodyCharts(localId, bodyCharts)
              .then(() => {
                setShowCustomEmailModal(true);
              })
              .finally(() => {
                setEmailLoading(false);
              });
          }}
        >
          <FontAwesomeIcon icon={faEnvelope} />
          {t("plan.email")}
        </Button>
        <Button
          disabled={localId === 0}
          block
          key="1"
          type="primary"
          loading={printLoading}
          onClick={() => printDownload("print")}
        >
          <FontAwesomeIcon icon={faPrint} />
          {t("print")}
        </Button>
        <Button
          disabled={localId === 0}
          block
          key="2"
          type="primary"
          loading={printLoading}
          onClick={() => printDownload("download")}
        >
          <FontAwesomeIcon icon={faDownload} />
          {t("download")}
        </Button>
      </Space.Compact>

      {!loading && templates.length === 0 && (
        <Result
          icon={<></>}
          title={t("template.nothingFound")}
          subTitle={t("cn.importTemplatesQ")}
          extra={[
            <Button
              type="primary"
              key="import"
              onClick={() => {
                post("note/importDefaultTemplates", {})
                  .then(() => {
                    getTemplates();
                  })
                  .catch(() => {});
              }}
            >
              {t("import")}
            </Button>
          ]}
        />
      )}
      <Space size={[4, 4]} wrap style={{ margin: "16px 0" }}>
        {!isTabletOrMobile ? (
          templates
            .filter(template => template.category === "quick")
            .map(template => (
              <Button
                key={template.id}
                onClick={() => insertHTML(template.template)}
              >
                {template.name}
              </Button>
            ))
        ) : (
          <Dropdown
            menu={{
              items: templates
                .filter(template => template.category === "quick")
                .map(template => ({
                  key: template.id,
                  label: template.name,
                  onClick: () => insertHTML(template.template)
                }))
            }}
          >
            <Button type="primary">
              <Space>
                {t("cn.quick")}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}

        {rfMenuProps.items.length > 0 && (
          <Dropdown menu={rfMenuProps}>
            <Button type="primary">
              <Space>
                {t("cn.rf")}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}
        {templateMenuProps.items.length > 0 && (
          <Dropdown menu={templateMenuProps}>
            <Button type="primary">
              <Space>
                {t("cn.tmplts")}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}

        <Space>
          <Button
            type="dashed"
            onClick={_ => navigate("/settings/clinical-note-templates")}
          >
            {t("cn.editTemplates")}
          </Button>
          <Button
            color="green"
            variant="solid"
            onClick={() => {
              setBodyChartId(0);
              setShowBodyChartModal(true);
            }}
          >
            {t("bodyChart.add")}
          </Button>
        </Space>
      </Space>
      {dataLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} layout="vertical" autoComplete="off">
          {/* ReactQuill text editor */}
          <Form.Item name="note" style={{ marginBottom: 16 }}>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              onKeyUp={() => {
                setContentChanged(true);
              }}
              onChangeSelection={e => {
                // console.log(e, "cs");
                if (e !== null) setQuillRange(e);
              }}
              style={{ height: "400px", marginBottom: 16 }}
            />
          </Form.Item>
        </Form>
      )}

      <div style={{ margin: "0 auto" }}>
        {bodyCharts.length === 0 ? (
          dataLoading ? (
            <Skeleton active />
          ) : (
            <Result
              // icon={<FontAwesomeIcon icon={faChildReaching} size="4x" />}
              icon={<></>}
              // title={t("cn.noNotes")}
              subTitle={t("bodyChart.noCharts")}
              extra={
                <Button
                  color="green"
                  variant="solid"
                  onClick={() => {
                    setBodyChartId(0);
                    setShowBodyChartModal(true);
                  }}
                >
                  {t("bodyChart.add")}
                </Button>
              }
            />
          )
        ) : (
          <div
            style={{ padding: "20px 0", marginTop: 20, textAlign: "center" }}
          >
            <h3>{t("bodyChart.titlep")}</h3>
            <Row gutter={[16, 16]} className="body-charts">
              {bodyCharts.map((b, index) => (
                <Col key={b.id}>
                  <Card
                    hoverable
                    actions={[
                      <Space.Compact
                        key="actions"
                        style={{ marginTop: "-8px" }}
                      >
                        <Button
                          block
                          color="primary"
                          variant="filled"
                          onClick={() => {
                            setBodyChartId(b.id);
                            setShowBodyChartModal(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                          {t("edit")}
                        </Button>

                        <Button
                          block
                          color="red"
                          variant="filled"
                          onClick={() => {
                            setBodyCharts(
                              bodyCharts.filter(bc => bc.id !== b.id)
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} /> {t("delete")}
                        </Button>
                      </Space.Compact>
                    ]}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setBodyChartId(b.id);
                        setShowBodyChartModal(true);
                      }}
                    >
                      <BodyChartThumbnail json={b.json} />
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            <Button
              block
              style={{ marginTop: 16 }}
              color="green"
              variant="solid"
              onClick={() => {
                setBodyChartId(0);
                setShowBodyChartModal(true);
              }}
            >
              {t("bodyChart.add")}
            </Button>
          </div>
        )}
      </div>

      <CustomNotesEmailModal
        id={localId}
        showModal={showCustomEmailModal}
        onCancel={() => {
          setShowCustomEmailModal(false);
        }}
        onDone={() => {
          setShowCustomEmailModal(false);
        }}
      />
      <BodyChartUpsertModal
        id={bodyChartId}
        json={bodyCharts.find(b => b.id === bodyChartId)}
        showModal={showBodyChartModal}
        onCancel={() => setShowBodyChartModal(false)}
        onDone={json => {
          setShowBodyChartModal(false);
          setContentChanged(true);
          if (bodyChartId === 0) {
            setBodyCharts([...bodyCharts, json]);
          } else {
            setBodyCharts(
              bodyCharts.map(b => (b.id === bodyChartId ? json : b))
            );
          }
        }}
      />
    </MyModal>
  );
};

export default NoteUpsertModal;
