import { Input, Form } from "antd";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { CustomExerciseDescription } from "../../types/ExerciseT";
import { post } from "../../api/Request";
import { ApiRes } from "../../types/General";
import ConfirmButton from "../../components/helper/ConfirmButton";
import MyModal from "../../components/MyModal";
const { TextArea } = Input;

interface Props {
  exercise: CustomExerciseDescription;
  showModal: boolean;
  onDone: () => void;
  onCancel: () => void;
}

const CustomExerciseDescriptionModal: React.FC<Props> = ({
  exercise,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      ...exercise,
      title_edited:
        exercise.title_edited.length > 0
          ? exercise.title_edited
          : exercise.title
    });
  }, [exercise, form]);
  const [loading, setLoading] = useState(false);

  const submit = v => {
    setLoading(true);

    post<ApiRes>("exercise/editCustomDescription", v)
      .then(r => {
        onDone();
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  return (
    <MyModal
      title={t("settings.editExerciseDescription")}
      open={showModal}
      onCancel={onCancel}
      okText={t("save")}
      okButtonProps={{ loading: loading }}
      cancelText={t("cancel")}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            submit(values);
          })
          .catch(info => {
            // console.log("Validate Failed:", info);
          })
          .finally(() => {});
      }}
    >
      {exercise && (
        <Form form={form} id="smsForm" layout="vertical" autoComplete="off">
          <Form.Item hidden name="exercise_id">
            <Input />
          </Form.Item>
          <Form.Item hidden name="lang">
            <Input />
          </Form.Item>

          <Form.Item
            label={t("exercise.yourTitle")}
            name="title_edited"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="desc_edited"
            label={t("exercise.yourDescription")}
            rules={[
              {
                required: true
              }
            ]}
          >
            <TextArea maxLength={2500} autoSize={{ minRows: 3, maxRows: 25 }} />
          </Form.Item>

          <h4>{t("exercise.originalDescription")}</h4>
          <h3>{exercise.title}</h3>
          {exercise.desc}

          <div style={{ marginTop: 32 }}>
            <ConfirmButton
              btnText={t("delete")}
              message={t("confirmations.delete")}
              btnProps={{ block: true, color: "red", variant: "filled" }}
              onConfirm={() => {
                post("exercise/deleteCustomDescription", {
                  exercise_id: exercise.exercise_id,
                  lang: exercise.lang
                })
                  .then(() => {
                    onDone();
                  })
                  .catch(_ => {});
              }}
            />
          </div>
        </Form>
      )}
    </MyModal>
  );
};

export default CustomExerciseDescriptionModal;
