let version = "3.32.2";

const currentURL = new URL(window.location.href);
const domain = currentURL.origin;
const prod = {
  OLD_SITE_URL: "https://www.rehabmypatient.com/",
  API_URL: "https://www.rehabmypatient.com/apiv3/",
  TELEREHAB_URL: "https://www.rehabmypatient.com/meeting/",
  RTL_LANGS: ["ar", "he", "fa", "ur", "ps", "ku", "sd", "dv", "ku-Arab"],
  PP_CLIENT_ID:
    "AYaLzCDbk_vC4tw0s0Vn3eaKKcqtMVCADltMVl1tKk70gKJK64pZjI44mXruhDNJnz-PbNzS4pfC9ocq", //  PROD
  STRIPE_KEY: "pk_live_V7pnYzoUQDwAv898KIIfSB4z00b3KY85RP",
  STRIPE_KEY_PL:
    "pk_live_51OfOK0BOQ6fvRTxUv8hux4M6Y4FsvTwF1MXlopR3zl8eVHUAk0qhOWnQ6cHTJJn2kxZJU2RHEOYzIBvTN5glp0or00fZjR0k42",
  BASE_URL: "https://app2.rehabmypatient.com/",
  SIF_URL:
    "https://sif.health/api/v1/oauth2/authorize?client_id=B3B2D5AD2F998AB5A1AD77C2343522B2F3852C89EE1B85BD439978D57A2948CB&scope=user.profile.read,org.patients.read",
  VERSION: version
};

const dev = {
  OLD_SITE_URL: "https://test.rehabmypatient.com/",
  API_URL: "https://test.rehabmypatient.com/apiv3/",
  TELEREHAB_URL: "https://www.rehabmypatient.com/meeting/",
  RTL_LANGS: ["ar", "he", "fa", "ur", "ps", "ku", "sd", "dv", "ku-Arab"],
  PP_CLIENT_ID:
    "Ae1_WhsSTIbEBBT9UEHFKcZdb-gxfVOug9URnW22S9xGsay2YAddugD__6npdHrjlas0-UMBzmh8OdO6", // test PP key
  STRIPE_KEY: "pk_test_euVA5cqlVp7DNniUSkYu04cT00HVU5jarU", // test stripe key
  STRIPE_KEY_PL:
    "pk_test_51OfOK0BOQ6fvRTxURb3IqTDmPMH5pMKDocISxJmwMP5PRKhp2SRZx1BCMVLipSBaFYjz47bCLaqJnLxTbC40ZHvS00vFOgZ69H",
  BASE_URL: domain + "/",
  SIF_URL:
    "https://sif.health/api/v1/oauth2/authorize?client_id=B3B2D5AD2F998AB5A1AD77C2343522B2F3852C89EE1B85BD439978D57A2948CB&scope=user.profile.read,org.patients.read",

  VERSION: version
};
// export const config = process.env.NODE_ENV === "development" ? dev : dev;

// Check the current URL to determine which configuration to use
function getConfigBasedOnURL() {
  // Replace this logic with your specific URL conditions
  const currentURL = window.location.href;

  if (
    currentURL.includes("loc.rehabmypatient.com") ||
    currentURL.includes("local.rehabmypatient.com") ||
    currentURL.includes("test3.rehabmypatient.com") ||
    currentURL.includes("apptest.rehabmypatient.com")
  ) {
    return dev;
  } else {
    return prod;
  }
}

export const config = getConfigBasedOnURL();
