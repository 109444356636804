import { fabric } from "fabric";
import { post } from "../../api/Request";

const canvasToImageData = json => {
  return new Promise((resolve, reject) => {
    try {
      const data = JSON.parse(json);
      const canvas = new fabric.StaticCanvas(null);

      if (data.backgroundImage) {
        fabric.Image.fromURL(data.backgroundImage.src, img => {
          canvas.setWidth(img.width);
          canvas.setHeight(img.height);

          canvas.loadFromJSON(json, () => {
            const imageData = canvas.toDataURL({
              format: "png",
              quality: 0.3
            });
            resolve(imageData);
          });
        });
      } else {
        reject("No background image found in the JSON");
      }
    } catch (error) {
      reject(`Error parsing JSON or processing canvas`);
    }
  });
};

export const uploadBodyCharts = async (id, charts) => {
  let validBodyCharts = charts.filter(chart => Number.isInteger(chart.id));
  validBodyCharts = charts;

  return Promise.all(
    validBodyCharts.map(async chart => ({
      id: chart.id,
      image_data: await canvasToImageData(chart.json)
    }))
  ).then(images => {
    return post("note/uploadBodyCharts", { images: images, id: id })
      .then(r => {
        return r.data;
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  });
};
