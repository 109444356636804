import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { get } from "../../api/Request";
import ConfirmButton from "../../components/helper/ConfirmButton";
import ValueLabel from "../../components/layout/ValueLabel";
import { useGlobalContext } from "../../context/GlobalContext";
import { formatDate } from "../../helpers/dateHelper";
import { useNavigate, Navigate } from "react-router-dom";

import { PlanViewDTO, TemplateTypes } from "../../types/Plan";
import { faCopy, faEdit, faShare } from "@fortawesome/free-solid-svg-icons";
import ShareToCommunity from "./ShareToCommunity";
import PatientSelect3 from "../../components/PatientSelect3";
import { useAuth } from "../../context/AuthContext";

interface Props {
  template: PlanViewDTO;
}

const TemplateInfo: React.FC<Props> = ({ template }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { language } = useGlobalContext();

  const [showCommunityModal, setShowCommunityModal] = useState(false);
  const [redirectToTemplates, setRedirectToTemplates] = useState(false);
  const [patient, setPatient] = useState(0);
  const [loading, setLoading] = useState(false);

  const assign = () => {
    setLoading(true);
    get<any>("template/assign/" + template.plan.id + "/" + patient)
      .then(r => {
        navigate("/patient/plan/" + r.data.plan_id, { replace: true });
      })
      .catch(_ => {})
      .finally(() => {
        setLoading(false);
      });
  };

  if (redirectToTemplates) {
    return <Navigate to={"/templates/"} />;
  }

  return (
    <>
      {template && (
        <>
          <Form layout="vertical">
            <Form.Item
              className="margin-top"
              label={<>{t("template.assignToPatient")}</>}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: t("planUpsert.pleaseSelectPatient")
                }
              ]}
            >
              <PatientSelect3
                isMulti={false}
                onChange={v => {
                  if (v) setPatient(v as number);
                }}
              />
            </Form.Item>

            <Button
              className="margin-top"
              block
              type="primary"
              disabled={!patient}
              style={{ marginTop: 0, marginBottom: 32 }}
              loading={loading}
              onClick={() => {
                assign();
              }}
            >
              {t("template.assign")}
            </Button>
          </Form>

          {template.plan.desc.length > 0 && (
            <ValueLabel label={t("description")}>
              {template.plan.desc}
            </ValueLabel>
          )}
          {template.plan.created && (
            <ValueLabel label={t("created")}>
              {formatDate(template.plan.created, language)}
            </ValueLabel>
          )}
          {template.plan.is_template !== TemplateTypes.none && (
            <ValueLabel label={t("template.author")}>
              {template.plan.member_id === 1
                ? t("template.rmp")
                : t("template.community")}
            </ValueLabel>
          )}
          <ValueLabel label={t("plan.useTracking")}>
            {template.plan.has_tracking ? t("yes") : t("no")}
          </ValueLabel>
          {template.plan.has_tracking && (
            <>
              <ValueLabel label={t("plan.duration")}>
                {template.plan.duration}
              </ValueLabel>
              <ValueLabel label={t("plan.prom")}>
                {template.prom
                  .map(p => {
                    return p.prom_name;
                  })
                  .join(", ")}
                {!template.prom.length && <>--</>}
              </ValueLabel>
              <ValueLabel label={t("plan.monitor")}>
                {template.monitor
                  .map(m => {
                    return m.name;
                  })
                  .join(", ")}
                {!template.monitor.length && <>--</>}
              </ValueLabel>
            </>
          )}

          <Button
            hidden={
              template.plan.is_template !== 1 ||
              (template.plan.practitioner_id !== user?.practitioner.id &&
                template.plan.practitioner_id !== 0)
            }
            className="margin-bottom"
            type="primary"
            block
            onClick={_ => {
              navigate(
                "/exercise-plan?id=" + template.plan.id + "&mode=template"
              );
            }}
            icon={<FontAwesomeIcon icon={faEdit} />}
          >
            {t("edit")}
          </Button>
          <Button
            hidden={template.plan.is_template !== TemplateTypes.user_template}
            className="margin-bottom  green-btn"
            type="primary"
            block
            onClick={_ => {
              setShowCommunityModal(true);
            }}
          >
            <FontAwesomeIcon icon={faShare} />{" "}
            {t("template.shareToCommunityTemplates")}
          </Button>

          <Button
            hidden={template.plan.is_template !== TemplateTypes.rmp_community}
            className="margin-bottom"
            type="primary"
            block
            onClick={_ => {
              get("template/copyToMyTemplates/" + template.plan.id)
                .then(() => {
                  setRedirectToTemplates(true);
                })
                .catch(_ => {});
            }}
          >
            <FontAwesomeIcon icon={faCopy} /> {t("template.copyToMyTemplates")}
          </Button>
          {template.plan.is_template === TemplateTypes.user_template && (
            <>
              <ConfirmButton
                btnText={t("delete")}
                message={t("confirmations.deleteTemplate")}
                btnProps={{ block: true, variant: "outlined", color: "red" }}
                onConfirm={() => {
                  get("template/delete/" + template.plan.id)
                    .then(() => {
                      setRedirectToTemplates(true);
                    })
                    .catch(_ => {});
                }}
              />
            </>
          )}
          <ShareToCommunity
            showModal={showCommunityModal}
            templateId={template.plan.id}
            name={template.plan.name}
            desc={template.plan.desc}
            onDone={() => setShowCommunityModal(false)}
            onCancel={() => setShowCommunityModal(false)}
          />
        </>
      )}
    </>
  );
};

export default TemplateInfo;
