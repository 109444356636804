import React, { useEffect, useRef, useState } from "react";
import Table, { ColumnsType } from "antd/lib/table";

import { useAuth } from "../../context/AuthContext";
import { get } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { useGlobalContext } from "../../context/GlobalContext";
import { formatDate } from "../../helpers/dateHelper";
import {
  ExercisesAndAdvices,
  MyExercise,
  MyExerciseUpsert
} from "../../types/MyExercises";
import {
  Typography,
  Image,
  Button,
  Input,
  Tabs,
  InputRef,
  Result,
  Segmented,
  Checkbox,
  Space
} from "antd";
import Column from "../../components/layout/Col";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChild,
  faChildReaching,
  faNewspaper,
  faPlus,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import UpsertMyExerciseModal from "./UpsertMyExerciseModal";
import debounce from "lodash/debounce";
import ConfirmButton from "../../components/helper/ConfirmButton";
import UpsertAdviceModal from "./UpsertAdviceModal";
import { List } from "antd-mobile";

import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import TranslateExercises from "./TranslateExercises";
import ViewPdfModal from "./ViewPdfModal";
import Pract from "../../components/helper/Pract";

const MyExercises: React.FC = () => {
  const { Search } = Input;
  const { user } = useAuth();
  const searchRef = useRef<InputRef>() as React.MutableRefObject<InputRef>;
  const { Text } = Typography;

  const { t } = useTranslation();
  const { language, isTabletOrMobile, isMobile } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [showArchived, setShowArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState<"exercises" | "advices">("exercises");
  const [exercises, setExercises] = useState<MyExercise[]>();
  const [advices, setAdvices] = useState<MyExercise[]>();
  const [source, setSource] = useState<MyExercise[]>([]);
  const newExercise: MyExerciseUpsert = {
    id: 0,
    category_id: null,
    parent_category_id: null,
    title: "",
    desc: "",
    video: "",
    image: ""
  };
  const [exercise, setExercise] = useState<MyExerciseUpsert>(newExercise);

  const [pdfLink, setPdfLink] = useState<null | string>(null);
  const [showUpsertExerciseModal, setShowUpsertExeriseModal] = useState(false);
  const [showUpsertAdviceModal, setShowUpsertAdviceModal] = useState(false);

  const getMyExercises = () => {
    setLoading(true);
    get<ExercisesAndAdvices>(
      "exercise/myExercisesAndAdviceSheets/" + showArchived
    )
      .then(r => {
        setExercises(r.data.exercises);
        setAdvices(r.data.advice_sheets);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getMyExercises();
  }, [user, showArchived]);

  useEffect(() => {
    if (tab === "advices" && advices) setSource(advices);
    else if (tab === "exercises" && exercises) setSource(exercises);
    // console.log("source", source);
  }, [tab, exercises, advices, source]);

  const filteredSource = source.filter(
    entry =>
      String(entry.title).toLowerCase().includes(search.toLowerCase()) ||
      String(entry.desc).toLowerCase().includes(search.toLowerCase())
  );

  const columns: ColumnsType<MyExercise> = [
    {
      title: t("created"),
      dataIndex: "last_update",
      key: "created",
      className: "pointer",
      sorter: (a, b) => a.last_update.localeCompare(b.last_update),
      render: date => (
        <Text type="secondary">{formatDate(date, language)}</Text>
      ),
      responsive: ["lg"]
    },
    {
      title: "",
      dataIndex: "image",
      key: "image",
      className: "pointer",
      render: (_, record) =>
        record.advice_sheet_link !== undefined ? (
          <Button
            onClick={e => {
              e.stopPropagation();
              setPdfLink(record.advice_sheet_link ?? null);
            }}
          >
            {t("advice.viewPdf")}
          </Button>
        ) : (
          <Image
            width="80px"
            height="60px"
            preview={false}
            style={{ objectFit: "cover", borderRadius: "8px" }}
            src={record.image}
          />
        ),
      responsive: ["lg"]
    },
    {
      title: t("name"),
      key: "name",
      className: "pointer",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => (
        <>
          {isTabletOrMobile ? (
            <List.Item
              key={record.id}
              clickable
              onClick={() => {
                // openPatient(p.id);
              }}
              prefix={
                <Image
                  width="40px"
                  height="40px"
                  preview={false}
                  style={{
                    objectFit: "cover",
                    marginTop: 16,
                    marginLeft: -8,
                    borderRadius: "10px"
                  }}
                  src={record.image}
                />
              }
              arrow={
                <>{isMobile && <FontAwesomeIcon icon={faChevronRight} />}</>
              }
              // prefix={
              //   <Image
              //     src={p.avatar}
              //     style={{ borderRadius: 20 }}
              //     fit="cover"
              //     width={40}
              //     height={40}
              //   />
              // }
              description={formatDate(record.last_update, language)}
            >
              {record.title}
            </List.Item>
          ) : (
            <Text className="tmain_col">{record.title}</Text>
          )}
        </>
      )
    },
    {
      title: t("description"),
      key: "desc",
      className: "pointer",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => (
        <Text type="secondary" className="one-line" style={{ maxWidth: 250 }}>
          {record.desc}
        </Text>
      ),
      responsive: ["md"]
    },
    {
      title: t("practitioner"),
      key: "practitioner",

      render: (_, record) => <Pract id={record.practitioner_id} />,
      responsive: ["lg"]
    },
    {
      key: "actions",
      className: "pointer",
      render: (text, record) => (
        <Space.Compact style={{ float: "right" }} size="middle">
          <Button
            onClick={e => {
              setExercise(record);
              if (tab === "advices") setShowUpsertAdviceModal(true);
              else setShowUpsertExeriseModal(true);
              e.stopPropagation();
            }}
            variant="filled"
            color="primary"
          >
            {t("edit")}
          </Button>
          <Button
            variant="filled"
            color="primary"
            hidden={record.advice_sheet_link === undefined}
            onClick={e => {
              setPdfLink(record.advice_sheet_link ?? null);
              e.stopPropagation();
            }}
          >
            {t("advice.viewPdf")}
          </Button>
          {record.active === 1 && (
            <ConfirmButton
              btnText={t("myExercises.archive")}
              message={t("confirmations.archive")}
              btnProps={{
                block: true,
                variant: "filled",
                color: "red"
              }}
              onConfirm={() => {
                get("exercise/archiveMyExercise/" + record.id)
                  .then(() => {
                    getMyExercises();
                  })
                  .catch(_ => {});
              }}
            />
          )}
          {record.active === 0 && (
            <Button
              color="green"
              variant="solid"
              onClick={e => {
                get("exercise/activateMyExercise/" + record.id)
                  .then(() => {
                    getMyExercises();
                  })
                  .catch(_ => {});
                e.stopPropagation();
              }}
              size="middle"
            >
              {t("myExercises.activate")}
            </Button>
          )}
        </Space.Compact>
      ),
      responsive: ["md"]
    }
  ];

  return (
    <>
      <Column size="standard" style={{ marginTop: -50 }} key="col">
        <MyPageHeader
          key="ph"
          // onBack={() => null}

          title={t("menu.myExercises")}
          // subTitle="This is a subtitle"
          extra={[
            <Space.Compact key="btns">
              <Button
                key="ane"
                color="green"
                variant="solid"
                onClick={() => {
                  setExercise(newExercise);
                  setShowUpsertExeriseModal(true);
                }}
              >
                <FontAwesomeIcon icon={faChild} />
                {t("exercise.addNew")}
              </Button>

              <Button
                key="cnp"
                color="green"
                variant="solid"
                onClick={() => {
                  setExercise({ ...newExercise, parent_category_id: 228 });
                  setShowUpsertAdviceModal(true);
                }}
              >
                <FontAwesomeIcon icon={faNewspaper} />
                {t("exercise.addNewAdvice")}
              </Button>
            </Space.Compact>
          ]}
        />
        {exercises && (
          <>
            {isTabletOrMobile ? (
              <Segmented
                options={[
                  {
                    label: t("menu.myExercises"),
                    value: "exercises"
                  },
                  {
                    label: t("advice.myAdviceSheets"),
                    value: "advices"
                  }
                ]}
                onChange={t => {
                  setTab(t as "exercises" | "advices");
                  setSearch("");
                }}
                block
                defaultValue={tab}
                size="large"
              />
            ) : (
              <Tabs
                centered={true}
                onChange={t => {
                  setTab(t as "exercises" | "advices");
                  setSearch("");
                }}
                defaultActiveKey={tab}
                className="patient-profile-tabs"
                items={[
                  {
                    label: t("menu.myExercises"),
                    key: "exercises"
                  },
                  {
                    label: t("advice.myAdviceSheets"),
                    key: "advices"
                  }
                ]}
              ></Tabs>
            )}

            <div className="box">
              <TranslateExercises onRefresh={getMyExercises} />

              {source.length > 0 && (
                <>
                  <Search
                    key="search"
                    // className="table-search vertical-margin"
                    // style={{ width: "50% " }}
                    ref={searchRef}
                    placeholder={t("search")}
                    onChange={debounce(e => {
                      // console.log(e);
                      setSearch(e.target.value);
                    }, 300)}
                    enterButton={<FontAwesomeIcon icon={faSearch} />}
                    loading={loading}
                    // value={search}
                    allowClear
                  />
                  <Table
                    pagination={{
                      position: ["bottomCenter"],
                      hideOnSinglePage: true,
                      pageSize: isTabletOrMobile ? 1000 : 10
                    }}
                    locale={{
                      emptyText: search.length > 0 && (
                        <Result
                          icon={<FontAwesomeIcon icon={faSearch} size="4x" />}
                          title={
                            tab === "exercises"
                              ? t("myExercises.nothingFound")
                              : t("advice.nothingFound")
                          }
                          subTitle={
                            tab === "exercises"
                              ? t("myExercises.nothingFound2")
                              : t("advice.nothingFound2")
                          }
                          extra={
                            <Button
                              type="primary"
                              color="green"
                              variant="solid"
                              onClick={() => {
                                if (tab === "exercises") {
                                  setExercise(newExercise);
                                  setShowUpsertExeriseModal(true);
                                } else {
                                  setExercise({
                                    ...newExercise,
                                    parent_category_id: 228
                                  });
                                  setShowUpsertAdviceModal(true);
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} />

                              {tab === "exercises"
                                ? t("myExercises.add")
                                : t("exercise.addNewAdvice")}
                            </Button>
                          }
                        />
                      )
                    }}
                    dataSource={search !== "" ? filteredSource : source}
                    rowClassName={record =>
                      record.active === 0 ? "table-deleted-row" : ""
                    }
                    // dataSource={[...source]}
                    columns={columns}
                    rowKey={"id"}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {
                          setExercise(record);
                          if (tab === "advices") setShowUpsertAdviceModal(true);
                          else setShowUpsertExeriseModal(true);
                          // event.stopPropagation();
                        },

                        onDoubleClick: event => {}, // double click row
                        onContextMenu: event => {}, // right button click row
                        onMouseEnter: event => {}, // mouse enter row
                        onMouseLeave: event => {} // mouse leave row
                      };
                    }}
                  />
                </>
              )}
              {/* {loading && <Skeleton active/>} */}

              {!showArchived && !loading && (
                <>
                  {exercises &&
                    exercises.length === 0 &&
                    tab === "exercises" && (
                      <Result
                        icon={
                          <FontAwesomeIcon icon={faChildReaching} size="4x" />
                        }
                        title={t("myExercises.noExercises")}
                        subTitle={t("myExercises.noExercises2")}
                        extra={
                          <Button
                            type="primary"
                            color="green"
                            variant="solid"
                            onClick={() => {
                              setExercise(newExercise);
                              setShowUpsertExeriseModal(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faChild} />
                            {t("exercise.addNew")}
                          </Button>
                        }
                      />
                    )}
                  {advices && advices.length === 0 && tab === "advices" && (
                    <Result
                      icon={<FontAwesomeIcon icon={faFileLines} size="4x" />}
                      title={t("advice.noAdvices")}
                      subTitle={t("advice.noAdvices2")}
                      extra={
                        <Button
                          type="primary"
                          color="green"
                          variant="solid"
                          onClick={() => {
                            setExercise({
                              ...newExercise,
                              parent_category_id: 228
                            });
                            setShowUpsertAdviceModal(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faNewspaper} />
                          {t("exercise.addNewAdvice")}
                        </Button>
                      }
                    />
                  )}
                </>
              )}
              <Checkbox
                style={{ marginTop: 16 }}
                onChange={e => {
                  setShowArchived(e.target.checked);
                }}
              >
                {t("showArchived")}
              </Checkbox>
            </div>
          </>
        )}
        <UpsertMyExerciseModal
          exercise={exercise}
          showModal={showUpsertExerciseModal}
          onCancel={() => {
            setShowUpsertExeriseModal(false);
          }}
          onDone={() => {
            setShowUpsertExeriseModal(false);
            getMyExercises();
          }}
        />
        <UpsertAdviceModal
          exercise={exercise}
          showModal={showUpsertAdviceModal}
          onCancel={() => {
            setShowUpsertAdviceModal(false);
          }}
          onDone={() => {
            setShowUpsertAdviceModal(false);
            getMyExercises();
          }}
        />
        <ViewPdfModal
          link={pdfLink}
          showModal={pdfLink !== null}
          onCancel={() => {
            setPdfLink(null);
          }}
          onDone={() => {
            setPdfLink(null);
          }}
        />
      </Column>
    </>
  );
};

export default MyExercises;
