import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Select } from "antd";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";
import { ApiResWithUser } from "../../types/General";
import moment from "moment-timezone";

const { Option } = Select;
interface AuthFormDTO {
  email: string;
  password: string;
}

// Get all timezones
const getTimezones = () => {
  const timeZones = moment.tz.names();

  console.log("timezones", timeZones);
  return timeZones
    .map(zone => ({
      label: `(GMT ${moment.tz(zone).format("Z")}) ${zone.replace(/_/g, " ")}`,
      value: zone
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
const EditProfile: React.FC = () => {
  const navigate = useNavigate();

  const { user, setUser } = useAuth();
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [timezones, setTimezones] = useState<
    { label: string; value: string }[]
  >([]);

  const onFinish = (values: AuthFormDTO) => {
    setLoading(true);
    post<ApiResWithUser>("account/practitionerEdit", values)
      .then(r => {
        // console.log("res data", r.data);
        if (r.data.user) setUser(r.data.user);
        // if (r.data.clinics.length > 1) navigate("/choose-clinic", {replace:true});
        // else navigate("/patients", {replace:true});
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // console.log(user);
    // setSelectedTimezone(user?.practitioner.timezone ?? "");
  }, [user, navigate]);

  useEffect(() => {
    setTimezones(getTimezones());
  }, []);

  return (
    <Form
      name="basic"
      layout="vertical"
      initialValues={{
        email: user?.practitioner.email,
        first_name: user?.practitioner.first_name,
        last_name: user?.practitioner.last_name,
        share_templates: user?.practitioner.share_templates,
        send_newsletter: user?.practitioner.send_newsletter ? 1 : 0,
        timezone: user?.practitioner.timezone
      }}
      onFinish={onFinish}
      autoComplete="on"
    >
      <Form.Item
        label={t("email")}
        name="email"

        // rules={[{ required: true, message: "Please input your email!" }]}
      >
        <Input required disabled type="email" />
      </Form.Item>
      <Form.Item
        label={t("firstName")}
        name="first_name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("lastName")}
        name="last_name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        tooltip={t("form.shareTemplates")}
        name="share_templates"
        valuePropName="checked"
      >
        <Checkbox>{t("form.shareTemplates")}</Checkbox>
      </Form.Item>

      <Form.Item tooltip={t("help.patientConsent")} name="send_newsletter">
        <Select>
          <Option value={0}>{t("form.unsubscribeNewsletterOption")}</Option>
          <Option value={1}>{t("form.subscribeNewsletterOption")}</Option>
        </Select>
      </Form.Item>

      <Form.Item name="timezone" label={t("timezone")}>
        <Select
          showSearch
          style={{ width: "100%" }}
          options={timezones}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          placeholder="Select timezone"
          value={selectedTimezone}
          onChange={value => setSelectedTimezone(value)}
        />
      </Form.Item>
      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit" block>
          {t("save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditProfile;
